const es = {
  Back: "Volver",
  "Schedule your next lesson": "Reserva tu próxima clase",
  "Book now": "Reserva ya",
  Availability: "Disponibilidad",
  Activity: "Actividad",
  Location: "Lugar",
  What: "Actividad",
  Date: "Fecha",
  Time: "Hora",
  "More info": "Más información",
  Tags: "Etiquetas",
  "Please login or signup to continue":
    "Inicie sessão ou registe-se para continuar",

  // booking form
  Participant: "Participante",
  Name: "Nombre",
  Age: "Edad",
  // "ID or Passport number": "Número de DNI o pasaporte",
  // ID: "CC/Pass.",
  "Phone number": "Número de teléfono",
  Phone: "Teléfono",
  // "Date of birth": "Data de nascimento",
  // DOB: "DN",
  "Add participant": "Añadir participante",
  "Extra notes": "Notas adicionales",
  Continue: "Continuar",
  Contacts: "Contactos",
  // "Save for next time": "Guardar para próxima vez",
  "Booking summary": "Resumen de la reserva",
  "Submitted, please check your email.":
    "¡Sujeto! Por favor, confirme la recepción de la reserva en su correo electrónico.",
  "Agree with terms and conditions": "Acepto los términos y condiciones",
  Link: "Enlace",
  "Read now": "Léer ahora",
  Edit: "Cambiar",
  Confirm: "Confirmar",
  "Sign in or sign up": "iniciar sesión",
  Admin: "Administración",
  "Account settings": "Configuraciones de la cuenta",
  "Sign out": "Cerrar sessión",
  "Sign up": "Crear cuenta",
  Login: "Iniciar sesión",
  login: "login",
  signup: "registro",
  "Verify password": "Repite la contraseña",
  //
  Home: "Portada",
  About: "Acerca de",
  Posts: "Novedades",
  Pricing: "Precios",
  "Terms and Conditions": "Términos y condiciones",
  //
  "My bookings": "Mis reservas",
  "Continue without account": "Continuar sin crear cuenta",
  //
  today: "hoy",
  "in X days": ({num}) => {
    switch (num) {
      case 0:
        return "hoy";
      case 1:
        return "mañana";
      case 2:
        return "despues de mañana";
      default:
        return `en ${num} días`;
    }
  },
  "X days after": ({num}) => {
    switch (num) {
      case 0:
        return null;
      case 1:
        return "un día después";
      default:
        return `${num} días después`;
    }
  },
  //
  "Product ID": "#",
  Item: "Item",
  Price: "Precio",
  // subscribe form
  "Be up to date": "Mantente actualizado",
  "Be notified when we post new lessons":
    "Recibe una notificación cuando publiquemos nuevas lecciones",
  Subscribe: "Suscribir",
  //
  "No planned classes, please check later":
    "No hay clases planeadas, por favor regrese más tarde",
  //
  All: "Todo",
  // edit booking
  "Manage Booking": "Gestionar reserva",
  "Cancel booking": "Cancelar reserva",
  "Yes, cancel booking": "Sí, cancelar la reserva",
  "No, do not cancel": "No, no cancelar",
  "Error loading booking": "Error al cargar la reserva",

  "Cookie policy text": "Utilizamos cookies apenas para ",

  "Privacy Policy": "Política de privacidad",
  "Terms & Conditions": "Términos y Condiciones",
  Cookies: "Cookies",
  "Subscribe to new classes email notifications":
    "Suscríbete a las notificaciones por email de nuevas clases",
  "Follow us": "Contactos",

  "Sold out!": "Agotado",

  "Pay now": "Pagar ahora",
  "Pay later": "Pagar después",
  "Payment canceled, please try again": "Pago cancelado, inténtalo de nuevo",
  "Payment processed successfully. Thank you ❤️":
    "Pago procesado con éxito. Gracias ❤️",

  "Subscribe to push notifications": "Suscribirse a notificaciones push 🔔",
  "Unsubscribe to push notifications":
    "Cancelar la suscripción a notificaciones push 🔕",

  "Total amount": "Total a pagar"
};

export default es;
