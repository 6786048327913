import React, {useEffect, useState} from "react";
import useFetch from "use-http";
import {Routes, Route, useParams} from "react-router-dom";
import {Formik, Field, Form} from "formik";
import {useNavigate} from "react-router-dom";
import {API_ENDPOINT} from "../../config";
import {Button} from "../../components";
import {List, ListItem, CTALink, ListFooter, Title} from "./components";

const ProductForm = ({
  onCreate,
  onUpdate,
  onDelete,
  initialValues,
  data,
  isNew
}) => {
  const [isDone, setIsDone] = useState(false);
  const {id} = useParams();
  const values = isNew ? initialValues : data.find((el) => el.id === id);
  const navigate = useNavigate();

  if (isDone) {
    navigate("../");
    return <div />;
  }

  return (
    <div className="border border-1">
      <Formik
        initialValues={values}
        onSubmit={(values) =>
          (isNew ? onCreate : onUpdate)(values) && setIsDone(true)
        }>
        <Form className="flex flex-col gap-2">
          <label htmlFor="name">Title</label>
          <Field
            type="text"
            id="title"
            name="title"
            placeholder="name"
            autoComplete="off"
          />
          <label htmlFor="name">Description</label>
          <Field
            type="text"
            id="description"
            name="description"
            placeholder="description"
            autoComplete="off"
          />
          <label htmlFor="name">Price (*100)</label>
          <Field
            type="number"
            thousandSeparator={true}
            id="price"
            name="price"
            placeholder="price"
            autoComplete="off"
          />
          <label htmlFor="name">Num uses</label>
          <Field
            type="text"
            id="num_uses"
            name="num_uses"
            placeholder="num uses"
            autoComplete="off"
          />
          <label htmlFor="name">Position</label>
          <Field
            type="text"
            id="order_num"
            name="order_num"
            placeholder="order_num"
            autoComplete="off"
          />
          <Button type="submit">{isNew ? "Create" : "Update"}</Button>
          <Button onClick={() => setIsDone(true)}>Cancel</Button>
          {!isNew && (
            <Button
              color="destructive"
              onClick={() => onDelete(id) && setIsDone(true)}>
              Delete
            </Button>
          )}
        </Form>
      </Formik>
    </div>
  );
};

export const Products = (props) => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const {get, post, put, del, response, loading, error} =
    useFetch(API_ENDPOINT);

  useEffect(() => {
    loadInitialData();
  }, []);

  async function loadInitialData() {
    const initialProducts = await get("/api/products");
    if (response.ok) {
      setProducts(initialProducts);
    }
  }

  const formProps = {
    data: products,
    initialValues: {
      name: "",
      num_uses: "1"
    },
    onCancel: () => navigate(-1),
    onCreate: async (values) => {
      const newProduct = await post("/api/products", values);
      if (response.ok) {
        setProducts([...products, newProduct]);
      }
    },
    onUpdate: async (values) => {
      const {id} = values;
      const updatedProduct = await put(`/api/products/${id}`, values);
      if (response.ok) {
        const newProducts = [...products];
        const index = products.findIndex((el) => el.id === id);
        newProducts[index] = updatedProduct;
        setProducts(newProducts);
      }
    },
    onDelete: async (id) => {
      const response = await del(`/api/products/${id}`);
      if (response.ok) {
        const newProducts = [...products];
        const index = products.findIndex((el) => el.id === id);
        delete newProducts[index];
        setProducts(newProducts);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        {error && "Error!"}
        {loading && "Loading..."}
        <div className="container safe-areas">
          <Routes>
            <Route
              path={`new`}
              element={<ProductForm {...formProps} isNew />}
            />
            <Route path={`:id`} element={<ProductForm {...formProps} />} />/
            <Route
              path={``}
              element={
                <List>
                  {products
                    .sort((a, b) => a.order_num - b.order_num)
                    .map((item, index) => (
                      <ListItem key={item.id} to={item.id}>
                        {item.title} ({item.price / 100}€)
                      </ListItem>
                    ))}
                  <ListFooter>
                    {products.length} items
                    <CTALink to={`new`}>Add new</CTALink>
                  </ListFooter>
                </List>
              }
            />
          </Routes>
        </div>
      </div>
    </>
  );
};
