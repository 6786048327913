import {useState} from "react";

export const Button = (props) => {
  const {className} = props;
  const otherProps = {...props};
  delete otherProps.className;

  let typeClassnames = "";
  switch (props.type) {
    case "small":
      typeClassnames = "py-1 px-2 text-xs";
      break;
    case "link":
      typeClassnames = "mx-2";
      break;
    case "muted":
      typeClassnames = "mx-2";
      break;
    default:
      typeClassnames = "py-2 px-2 text-sm";
      break;
  }
  switch (props.color) {
    case "destructive":
      typeClassnames +=
        " border-2 text-red-500 border-red-500 hover:bg-red-500 hover:text-white";
      break;
    case "secondary":
      typeClassnames +=
        " border text-blue-500 border-blue-500 hover:bg-blue-300 hover:text-white ";
      break;
    case "link":
      typeClassnames += " underline text-blue-500";
      break;
    case "muted":
      typeClassnames += " underline text-blue-500";
      break;
    default:
      typeClassnames +=
        " border-2 text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white text-s";
      break;
  }

  return (
    <button
      className={`font-bold ${typeClassnames} rounded ${className} disabled:opacity-50 transition-all`}
      {...otherProps}>
      {props.children}
    </button>
  );
};

export const SignInButton = (props) => {
  return (
    <button
      className="flex gap-2 border p-2 items-center"
      type="button"
      {...props}>
      {props.children}
    </button>
  );
};

export const ConfirmButton = ({
  confirmAction,
  cancelTitle,
  confirmTitle,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)} {...props} />
      {open && (
        <div
          className="flex items-center justify-center fixed left-0 bottom-0 w-full h-full "
          style={{background: "rgba(0, 0, 0, 0.7)"}}>
          <div className="bg-white rounded-lg w-1/2 filter drop-shadow-lg">
            <div className="flex 	flex-col items-start p-4 gap-5">
              <div className="flex-grow	flex w-full items-center">
                <div className="text-gray-900 font-medium text-lg">
                  {props.title || `Confirm action`}
                </div>
              </div>
              <div className="flex-grow	flex w-full flex-col sm:flex-row justify-end gap-2">
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => {
                    setOpen(false);
                    confirmAction();
                  }}>
                  {confirmTitle || `Confirm action`}
                </button>
                <button
                  className="bg-transparent hover:bg-gray-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                  onClick={() => setOpen(false)}>
                  {cancelTitle || `Confirm action`}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
