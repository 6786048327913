import React from "react";
import {BackButton} from "./back-button";

export const PageTitle = (props) => {
  return (
    <h2 className="my-10 text-2xl font-bold leading-7 text-gray-900 text-center flex flex-col gap-4 items-center">
      {props.backTitle && <BackButton title={props.backTitle} />}
      {props.children}
    </h2>
  );
};
