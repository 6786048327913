import React from "react";

export const LoadingIndicator = ({size = "h-10 w-10"}) => (
  <svg
    className={`animate-spin ${size} text-blue-700`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24">
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
);

export const Loading = () => {
  return (
    <div
      className="flex items-center justify-center fixed left-0 bottom-0 w-full h-full z-50"
      style={{}}>
      <div className="bg-white rounded-lg p-10 flex justify-center content-center filter drop-shadow-2xl">
        <LoadingIndicator />
      </div>
    </div>
  );
};

export const SmallLoading = () => {
  return (
    <span className="inline-block">
      <LoadingIndicator size="h-5 w-5" />
    </span>
  );
};
