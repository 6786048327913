import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {PageTitle} from "../../components";
import {TranslateString} from "../../lang-provider";
import {fetchAuxData} from "../../ducks/aux";

export const About = (props) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.aux?.data?.settings);
  React.useEffect(() => {
    dispatch(fetchAuxData());
  }, [dispatch]);

  return (
    <React.Fragment>
      <PageTitle>
        <TranslateString>About</TranslateString>
      </PageTitle>
      <div>{settings?.about_text?.value}</div>
    </React.Fragment>
  );
};
