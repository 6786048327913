import React, {useEffect, useState} from "react";
import useFetch from "use-http";
import {Routes, Route, useParams} from "react-router-dom";
import {Formik, Field, Form} from "formik";
import {useNavigate} from "react-router-dom";
import {API_ENDPOINT} from "../../config";
import {Button} from "../../components";
import {List, ListItem, CTALink, ListFooter, Title} from "./components";

const InstructorForm = ({
  onCreate,
  onUpdate,
  onDelete,
  initialValues,
  data,
  isNew,
  path
}) => {
  const [isDone, setIsDone] = useState(false);
  const {id} = useParams();
  const values = isNew ? initialValues : data.find((el) => el.id === id);
  const navigate = useNavigate();

  if (isDone) {
    navigate("../");
    return <div />;
  }

  return (
    <div className="border border-1">
      <Formik
        initialValues={values}
        onSubmit={(values) =>
          (isNew ? onCreate : onUpdate)(values) && setIsDone(true)
        }>
        <Form className="flex flex-col gap-2">
          <label htmlFor="name">Name</label>
          <Field
            type="text"
            id="name"
            name="name"
            placeholder="name"
            autoComplete="off"
          />
          <label htmlFor="name">Email</label>
          <Field
            type="text"
            id="email"
            name="email"
            placeholder="email"
            autoComplete="off"
          />
          <label htmlFor="name">Phone</label>
          <Field
            type="text"
            id="phone"
            name="phone"
            placeholder="phone"
            autoComplete="off"
          />
          <Button type="submit">{isNew ? "Create" : "Update"}</Button>
          <Button onClick={() => setIsDone(true)}>Cancel</Button>
          {!isNew && (
            <Button
              color="destructive"
              onClick={() => onDelete(id) && setIsDone(true)}>
              Delete
            </Button>
          )}
        </Form>
      </Formik>
    </div>
  );
};

export const Instructors = (props) => {
  const [instructors, setInstructors] = useState([]);
  const navigate = useNavigate();
  const {get, post, put, del, response, loading, error} =
    useFetch(API_ENDPOINT);

  useEffect(() => {
    loadInitialData();
  }, []);

  async function loadInitialData() {
    const initialInstructors = await get("/api/instructors");
    if (response.ok) {
      setInstructors(initialInstructors);
    }
  }

  const formProps = {
    data: instructors,
    initialValues: {
      name: "",
      color: "#0000ff"
    },
    onCancel: () => navigate(-1),
    onCreate: async (values) => {
      const newInstructor = await post("/api/instructors", values);
      if (response.ok) {
        setInstructors([...instructors, newInstructor]);
      }
    },
    onUpdate: async (values) => {
      const {id} = values;
      const updatedInstructor = await put(`/api/instructors/${id}`, values);
      if (response.ok) {
        const newInstructors = [...instructors];
        const index = instructors.findIndex((el) => el.id === id);
        newInstructors[index] = updatedInstructor;
        setInstructors(newInstructors);
      }
    },
    onDelete: async (id) => {
      const response = await del(`/api/instructors/${id}`);
      if (response.ok) {
        const newInstructors = [...instructors];
        const index = instructors.findIndex((el) => el.id === id);
        delete newInstructors[index];
        setInstructors(newInstructors);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        {error && "Error!"}
        {loading && "Loading..."}
        <div className="container safe-areas">
          <Routes>
            <Route
              path={`new`}
              element={<InstructorForm {...formProps} isNew />}
            />
            <Route path={`:id`} element={<InstructorForm {...formProps} />} />/
            <Route
              path={``}
              element={
                <List>
                  {instructors.map((item, index) => (
                    <ListItem key={item.id} to={item.id}>
                      {item.name}
                    </ListItem>
                  ))}
                  <ListFooter>
                    {instructors.length} items
                    <CTALink to={`new`}>Add new</CTALink>
                  </ListFooter>
                </List>
              }
            />
          </Routes>
        </div>
      </div>
    </>
  );
};
