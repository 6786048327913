const fr = {
  Back: "Retour",
  "Schedule your next lesson": "Programmer votre prochaine leçon",
  "Book now": "Réserver maintenant",
  Availability: "Disponibilité",
  Activity: "Activité",
  Location: "Emplacement",
  What: "Quoi",
  Date: "Date",
  Time: "Heure",
  "More info": "Plus d'infos",
  Tags: "Tags",
  "Please login or signup to continue":
    "Veuillez vous connecter ou vous inscrire pour continuer",
  Participant: "Participant",
  Name: "Nom",
  "ID or Passport number": "Numéro d'identité ou passeport",
  ID: "ID/Passeport",
  "Phone number": "Numéro de téléphone",
  Age: "Âge",
  Phone: "Téléphone",
  "Date of birth": "Date de naissance",
  DOB: "DOB",
  "Add participant": "Ajouter participant",
  "Extra notes": "Notes supplémentaires",
  Continue: "Continuer",
  Contacts: "Contacts",
  "Save for next time": "Enregistrer pour la prochaine fois",
  "Booking summary": "Résumé de la réservation",
  "Submitted, please check your email.":
    "Soumis, veuillez vérifier votre email.",
  "Agree with terms and conditions": "Accepter les termes et conditions",
  Link: "Lien",
  "Read now": "Lire maintenant",
  Edit: "Modifier",
  Confirm: "Confirmer",
  "Sign in or sign up": "Se connecter ou s'inscrire",
  Admin: "Administration",
  "Account settings": "Paramètres du compte",
  "Sign out": "Déconnexion",
  "Sign up": "S'inscrire",
  Login: "Se connecter",
  login: "connexion",
  signup: "inscription",
  "Verify password": "Vérifier le mot de passe",
  Home: "Accueil",
  About: "À propos",
  Posts: "Posts",
  Pricing: "Tarification",
  "Terms and Conditions": "Termes et conditions",
  "My bookings": "Mes réservations",
  "Continue without account": "Continuer sans compte",
  today: "aujourd'hui",
  "Product ID": "#",
  Item: "Article",
  Price: "Prix",
  "Be up to date": "Restez informé",
  "Be notified when we post new lessons":
    "Soyez averti lorsque nous publions de nouvelles leçons",
  Subscribe: "S'abonner",
  "Want to be up to date? Click here": "Vous voulez être informé ? Cliquez ici",
  "No planned classes, please check later":
    "Aucune classe prévue, veuillez vérifier plus tard",
  All: "Tout",
  "Manage Booking": "Gérer la réservation",
  "Cancel booking": "Annuler la réservation",
  "Yes, cancel booking": "Oui, annuler la réservation",
  "No, do not cancel": "Non, ne pas annuler",
  "Error loading booking": "Erreur dans le chargement de la réservation",
  "Cookie policy text": "Nous utilisons des cookies uniquement pour ",
  "Privacy Policy": "Politique de confidentialité",
  "Terms & Conditions": "Termes et conditions",
  Cookies: "Cookies",
  "Subscribe to new classes email notifications":
    "S'abonner aux notifications par email pour les nouvelles classes",
  "Follow us": "Suivez-nous",
  "Sold out!": "Épuisé",

  "Pay now": "Payer maintenant",
  "Pay later": "Payer plus tard",
  "Payment canceled, please try again": "Paiement annulé, veuillez réessayer",
  "Payment processed successfully. Thank you ❤️":
    "Paiement effectué avec succès. Merci ❤️",
  "Subscribe to push notifications": "S'abonner aux notifications push 🔔",
  "Unsubscribe to push notifications":
    "Se désabonner des notifications push 🔕",

  "Total amount": "Total à payer"
};

export default fr;
