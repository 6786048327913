import {useEffect, useState} from "react";

// TODO: check concurrency issues
//use-http.com/#/?id=installation

export const useFetch = (baseUrl, options, depArray = null) => {
  const [error, setError] = useState(null);
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    if (depArray && depArray.length === 0) {
      get("");
    }
  }, depArray);

  const performFetch = async (path, options) => {
    const url = [baseUrl, path].join("");
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(url, options);
      setResponse(response);
      if (!response.ok) {
        throw new Error("Request failed.");
      }
      const responseData = await response.json();
      setData(responseData);
      // return responseData;
      return options.returnHeaders
        ? [
            responseData,
            [...response.headers.entries()].reduce((o, [k, v]) => {
              o[k] = v;
              return o;
            }, {})
          ]
        : responseData;
    } catch (err) {
      console.error(err);
      setError(err.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const get = async (url, options = {}) => {
    return await performFetch(url, options);
  };

  const post = async (url, body, options = {}) => {
    return await performFetch(url, {
      ...options,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  };

  const put = async (url, body, options = {}) => {
    return await performFetch(url, {
      ...options,
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  };

  const del = async (url, body, options = {}) => {
    return await performFetch(url, {
      ...options,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  };

  return {
    get,
    post,
    put,
    del,
    loading,
    error,
    response,
    data
  };
};
