import React, {useEffect, useState} from "react";
import useFetch from "use-http";
import {
  Routes,
  Route,
  Link,
  useParams,
  Navigate,
  useNavigate
} from "react-router-dom";
import {Formik, Field, Form} from "formik";
import {API_ENDPOINT} from "../../config";
import {Button} from "../../components";
import {MdRadioButtonChecked, MdRadioButtonUnchecked} from "react-icons/md";
import {List, ListItem, CTALink, ListFooter, Title} from "./components";

const colorArray = [
  "#C0C0C0",
  "#808080",
  "#FF0000",
  "#800000",
  "#FFFF00",
  "#808000",
  "#00FF00",
  "#008000",
  "#00FFFF",
  "#008080",
  "#0000FF",
  "#000080",
  "#FF00FF",
  "#800080"
];

const ColorsEditor = ({field, colors, form, ...props}) => {
  return (
    <div className="flex flex-row gap-2">
      {colors.map((el, index) => (
        <span
          className="block w-8 h-8 flex justify-center items-center"
          style={{backgroundColor: el}}
          key={index}
          onClick={() => form.setFieldValue(field.name, el)}>
          {field.value === el ? <MdRadioButtonChecked /> : ""}
        </span>
      ))}
    </div>
  );
};

const TagForm = ({
  onCreate,
  onUpdate,
  onDelete,
  initialValues,
  data,
  isNew,
  path
}) => {
  const [isDone, setIsDone] = useState(false);
  const {id} = useParams();
  const values = isNew ? initialValues : data.find((el) => el.id === id);

  const navigate = useNavigate();

  if (isDone) {
    navigate("../");
    return <div />;
  }

  return (
    <div className="border border-1">
      <Formik
        initialValues={values}
        onSubmit={(values) =>
          (isNew ? onCreate : onUpdate)(values) && setIsDone(true)
        }>
        <Form className="flex flex-col gap-2">
          <label htmlFor="name">First Name</label>
          <Field
            type="text"
            id="name"
            name="name"
            placeholder="name"
            autoComplete="off"
          />
          <label htmlFor="color">Color</label>
          <Field name="color" component={ColorsEditor} colors={colorArray} />
          <Button type="submit">{isNew ? "Create" : "Update"}</Button>
          <Button onClick={() => setIsDone(true)}>Cancel</Button>

          {!isNew && (
            <Button
              color="destructive"
              onClick={() => onDelete(id) && setIsDone(true)}>
              Delete
            </Button>
          )}
        </Form>
      </Formik>
    </div>
  );
};

export const Tags = (props) => {
  const [tags, setTags] = useState([]);
  const navigate = useNavigate();
  const {get, post, put, del, response, loading, error} =
    useFetch(API_ENDPOINT);

  useEffect(() => {
    loadInitialData();
  }, []);

  async function loadInitialData() {
    const initialTags = await get("/api/tags");
    if (response.ok) {
      setTags(initialTags);
    }
  }

  const formProps = {
    data: tags,
    initialValues: {
      name: "",
      color: "#0000ff"
    },
    onCancel: () => navigate(-1),
    onCreate: async (values) => {
      const newTag = await post("/api/tags", values);
      if (response.ok) {
        setTags([...tags, newTag]);
      }
    },
    onUpdate: async (values) => {
      const {id} = values;
      const updatedTag = await put(`/api/tags/${id}`, values);
      if (response.ok) {
        const newTags = [...tags];
        const index = tags.findIndex((el) => el.id === id);
        newTags[index] = updatedTag;
        setTags(newTags);
      }
    },
    onDelete: async (id) => {
      const response = await del(`/api/tags/${id}`);
      if (response.ok) {
        const newTags = [...tags];
        const index = tags.findIndex((el) => el.id === id);
        delete newTags[index];
        setTags(newTags);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        {error && "Error!"}
        {loading && "Loading..."}
        <div className="container mx-auto px-3 safe-areas">
          <Routes>
            <Route path={`new`} element={<TagForm {...formProps} isNew />} />
            <Route path={`/:id`} element={<TagForm {...formProps} />} />
            <Route
              path={``}
              element={
                <>
                  <List>
                    {tags.map((tag, index) => (
                      <ListItem className="back" key={tag.id} to={tag.id}>
                        {tag.name}
                        <span
                          className="rounded-full w-5 h-5 inline-block"
                          style={{backgroundColor: tag.color}}></span>
                      </ListItem>
                    ))}
                  </List>
                  <ListFooter>
                    {tags.length} items
                    <CTALink to={`new`}>Add new</CTALink>
                  </ListFooter>
                </>
              }
            />
          </Routes>
        </div>
      </div>
    </>
  );
};
