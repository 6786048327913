import {Link, useNavigate} from "react-router-dom";

import {FaUserAlt} from "react-icons/fa";

import {AuthConsumer} from "../auth-provider";
import {LangConsumer, TranslateString} from "../lang-provider";

import {config} from "../config";
import {AnimatedDropdown} from "./animated-dropdown";
import {LanguageSelector} from "./language-selector";

const MenuButton = ({children, ...props}) => (
  <button
    className="inline-flex gap-2 justify-center items-center w-full h-8 px-2 rounded-md border border-gray-300 shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
    type="button"
    id="options-menu"
    aria-haspopup="true"
    {...props}>
    {children}
  </button>
);

const UserDropdown = (props) => {
  const {user} = props;
  const navigate = useNavigate();
  return (
    <AnimatedDropdown
      anchor={(open, setOpen) => (
        <MenuButton onClick={() => setOpen(!open)}>
          {user.photo_url && (
            <img
              className="inline-block h-5 w-5 rounded-full ring-2 ring-white"
              alt="avatar"
              src={user.photo_url}
            />
          )}
          <FaUserAlt />
        </MenuButton>
      )}>
      <Link to="/bookings" role="menuitem">
        <TranslateString>My bookings</TranslateString>
      </Link>
      <Link to="/me" role="menuitem">
        <TranslateString>Account settings</TranslateString>
      </Link>
      <AuthConsumer>
        {(auth) => (
          <span
            role="menuitem"
            onClick={() => {
              auth.logout();
              navigate("/");
            }}>
            <TranslateString>Sign out</TranslateString>
          </span>
        )}
      </AuthConsumer>
    </AnimatedDropdown>
  );
};

const LoginButton = (props) => {
  return (
    <Link to="/login" className="">
      <TranslateString>Sign in or sign up</TranslateString>
    </Link>
  );
};

const MenuItems = () => (
  <>
    <Link className="" to="/" role="menuitem">
      <TranslateString>Home</TranslateString>
    </Link>
    <Link className="" to="/about" role="menuitem">
      <TranslateString>About</TranslateString>
    </Link>
    <Link className="" to="/posts" role="menuitem">
      <TranslateString>Posts</TranslateString>
    </Link>
    <Link className="" to="/pricing" role="menuitem">
      <TranslateString>Pricing</TranslateString>
    </Link>
    <AuthConsumer>
      {({isSignedIn, user, providerId}) => {
        return isSignedIn ? (
          !!user.is_admin ? (
            <Link
              to="/manage"
              role="menuitem"
              className="bg-black text-white p-1">
              <TranslateString>Admin</TranslateString>
            </Link>
          ) : null
        ) : (
          <LoginButton />
        );
      }}
    </AuthConsumer>
  </>
);

const Nav = (props) => {
  return (
    <header className="container mx-auto px-3 safe-areas flex items-center justify-between py-4">
      <div className="flex items-center justify-between">
        <h1 className="leading-none text-grey-darkest">
          <Link
            className="no-underline text-sm text-grey-darkest flex items-center gap-2 whitespace-nowrap"
            to="/">
            <img
              src={`${process.env.PUBLIC_URL}${config.logo}`}
              alt="logo"
              className="h-8"
            />
            {config.name}
          </Link>
        </h1>
      </div>

      <div className="gap-2 flex text-sm items-center">
        <div className="gap-2 hidden md:flex items-center">
          <MenuItems />
        </div>

        <AnimatedDropdown
          className="md:hidden"
          anchor={(open, setOpen) => (
            <MenuButton onClick={() => setOpen(!open)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  d="M 4 6 h 16 M 4 12 h16 M 4 18 h 16"
                />
              </svg>
            </MenuButton>
          )}>
          <MenuItems />
        </AnimatedDropdown>

        <AuthConsumer>
          {({isSignedIn, user, providerId}) => {
            return isSignedIn ? <UserDropdown user={user} /> : null;
          }}
        </AuthConsumer>

        <LangConsumer>
          {(lang, setLang, langs, descriptions) => (
            <LanguageSelector {...{lang, setLang, langs, descriptions}} />
          )}
        </LangConsumer>
      </div>
    </header>
  );
};

export default Nav;
