import React from "react";
import {useSelector} from "react-redux";
import {TranslateString} from "../../lang-provider";

export const HomeFilter = (props) => {
  const {selectedActivity, handleSelectActivity} = props;

  const data = useSelector((state) => state.aux.data);

  const normalCss =
    "text-xs uppercase text-gray-600 py-4 px-2 block hover:text-blue-500 focus:outline-none ";
  const selectedCss =
    "text-xs uppercase text-gray-600 py-4 px-2 block hover:text-blue-500 focus:outline-none text-blue-500 border-b-2 font-medium border-blue-500";
  return (
    <div className="max-w-full overflow-scroll mb-8">
      <nav className="flex flex-row justify-center">
        <button
          onClick={() => handleSelectActivity()}
          className={!selectedActivity ? selectedCss : normalCss}>
          <TranslateString>All</TranslateString>
        </button>
        {data.activities.map((act) => (
          <button
            key={act.id}
            onClick={() => handleSelectActivity(act.id)}
            className={selectedActivity === act.id ? selectedCss : normalCss}>
            {act.name}
            <br />
            {act.place}
          </button>
        ))}
      </nav>
    </div>
  );
};
