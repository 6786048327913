import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {TranslateString} from "../lang-provider";
import {PageTitle} from "../components";
import {fetchJson} from "../api";

export function SubscribeConfirm({}) {
  const [ok, setOk] = useState(false);
  const {id} = useParams();

  const subscribeConfirm = async () => {
    const url = `/api/subscribers`;
    try {
      await fetchJson(`${url}/${id}`, {
        method: "PUT",
        body: {verified: true}
      });
      setOk(true);
    } catch (e) {
      alert(e);
      return false;
    }
    return true;
  };

  return (
    <React.Fragment>
      <PageTitle>
        <TranslateString>Confirm subscription</TranslateString>
      </PageTitle>
      <div className="card p-4 text-center">
        {!ok ? (
          <button
            className="bg-blue-500 p-5 hover:bg-blue-100 color-white"
            onClick={() => subscribeConfirm()}>
            <TranslateString>Confirm</TranslateString>
          </button>
        ) : (
          <div>
            <TranslateString>Verified. Thank you</TranslateString>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
