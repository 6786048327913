import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FaGoogle, FaFacebookF, FaApple} from "react-icons/fa";
import {useNavigate} from "react-router-dom";

import {Alert, Button, PageTitle, SignInButton} from "../../components";
import {loginUser} from "../../ducks/user";

import {API_ENDPOINT} from "../../config";
import {TranslateString} from "../../lang-provider";

const __Login = (props) => {
  // const {referrer} = props.location.state
  //   ? props.location.state
  //   : {referrer: "/"};
  const referrer = "/";
  const {user} = props;

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate(referrer);
    }
  }, [referrer, user]);

  const handleLogin = (e) => {
    props.loginUser(email, password);
    e.preventDefault();
  };

  const handleRegister = (e) => {
    e.preventDefault();
    navigate("/register");
  };

  return (
    <>
      <PageTitle>
        <TranslateString>Login</TranslateString>
      </PageTitle>

      <div>
        <div className="container">
          {props.error && (
            <div className="text-center w-4/5 md:w-1/3 py-4 mx-auto rounded">
              <Alert type="error">
                <TranslateString>Login failed</TranslateString>
              </Alert>
            </div>
          )}

          <form className="bg-blue-100 text-center px-3 py-4 w-4/5 mx-auto rounded">
            <input
              type="text"
              placeholder="Email"
              className="block w-full mx-auto text-sm py-2 px-3 rounded"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              className="block w-full mx-auto text-sm py-2 px-3 rounded my-3"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button className="mx-auto w-full" onClick={handleLogin}>
              <TranslateString>Login</TranslateString>
            </Button>
            <p className="mt-5 ">
              Don't have an account yet?
              <Button
                type="link"
                color="link"
                className=""
                onClick={handleRegister}>
                <TranslateString>Sign up</TranslateString>
              </Button>
            </p>
          </form>

          <div className="text-center w-4/5 md:w-1/3 py-4 mx-auto rounded hidden">
            <div className="flex flex-col gap-2">
              <SignInButton
                onClick={() => {
                  alert("work in progress 🚧");
                }}>
                <FaGoogle />
                Signin with Google
              </SignInButton>
              <SignInButton
                onClick={() => {
                  const url = `${API_ENDPOINT}/api/auth/facebook`;
                  const name = "facebook_login";
                  const specs = "width=500,height=500";
                  window.open(url, name, specs);
                }}>
                <FaFacebookF />
                Signin with Facebook
              </SignInButton>
              <SignInButton
                onClick={() => {
                  alert("work in progress 🚧");
                  // const url = `${API_ENDPOINT}/api/auth/apple`;
                  // const name = "apple_login";
                  // const specs = "width=500,height=500";
                  // window.open(url, name, specs);
                }}>
                <FaApple />
                Signin with Apple
              </SignInButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
    errorMessage: state.user.errorMessage,
    user: state.user.data
  };
};

const mapDispatchtoProps = {loginUser};

export const Login = connect(mapStateToProps, mapDispatchtoProps)(__Login);
