import React, {useState} from "react";
import {TranslateString, useLangProvider} from "../lang-provider";
import {fetchJson} from "../api";

// TODO move this to a utils file
export const subscribe = async (email, lang) => {
  const url = `/api/subscribers`;
  try {
    await fetchJson(url, {
      method: "POST",
      body: {email, lang}
    });
  } catch (e) {
    return false;
  }
  return true;
};

export function SubscribeForm({}) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const {T, lang} = useLangProvider();
  return !open ? (
    <div className="card p-4 text-center" onClick={() => setOpen(true)}>
      <p className="text-base md:text-sm text-gray-700 mt-3">
        <TranslateString>Want to be up to date? Click here</TranslateString>
      </p>
    </div>
  ) : (
    <div className=" bg-gray-100 rounded-sm">
      <div className="card p-4 text-center">
        <div className="card-text">
          <h1 className="text-xl md:text-2xl font-bold text-gray-900">
            <TranslateString>Be up to date</TranslateString>
          </h1>
          <p className="text-base md:text-lg text-gray-700 mt-3">
            <TranslateString>
              Be notified when we post new lessons
            </TranslateString>
          </p>
        </div>
        <div className="card-mail flex items-center my-10">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border-l border-t border-b border-gray-200 rounded-l-md w-full text-base md:text-lg px-3 py-2"
            placeholder={T("Email")}
          />
          <button
            className="bg-blue-500 hover:bg-blue-600 hover:border-blue-600 text-white font-bold capitalize px-3 py-2 text-base md:text-lg rounded-r-md border-t border-r border-b border-orange-500"
            onClick={async () => {
              const ok = await subscribe(email, lang);
              if (ok) {
                setEmail("");
                alert("OK!");
                // FIXME disabled because duplicates will trigger error
                // } else {
                //   alert("Oops!");
              }
            }}>
            <TranslateString>Subscribe</TranslateString>
          </button>
        </div>
        {/* TODO: add social media here */}
      </div>
    </div>
  );
}
