import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import {TranslateString, useLangProvider} from "../../lang-provider";
import {Alert, Button, PageTitle} from "../../components";
import {signupUser, clearRegistration} from "../../ducks/user";

export const Register = (props) => {
  const dispatch = useDispatch();
  const {error, errorMessage, registrationOk} = useSelector((state) => ({
    loading: state.user.loading,
    error: state.user.error,
    errorMessage: state.user.errorMessage,
    registrationOk: state.user.registrationOk
  }));
  const navigate = useNavigate();
  const {T} = useLangProvider();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  // const data = useSelector((state) => state.data);

  const message = T("Registration successful, please login");

  useEffect(() => {
    if (registrationOk) {
      console.log("Redirect to login");
      navigate("/login", {
        flash: {message}
      });
    }
    return () => {
      dispatch(clearRegistration());
    };
  }, [props.history, props, registrationOk, message, dispatch]);

  const handleSignup = (e) => {
    dispatch(signupUser(email, password));
    e.preventDefault();
  };

  const passOk = password === password2 && password.length > 0;
  const formOk = email.length && passOk;
  const pass2Ok = password2.length === 0 || passOk;

  const handleLogin = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  return (
    <>
      <PageTitle>
        <TranslateString>Sign up</TranslateString>
      </PageTitle>

      <div>
        <div className="container">
          {error && (
            <div className="text-center w-4/5 md:w-1/3 py-4 mx-auto rounded">
              <Alert type="error">
                <TranslateString>Registration failed</TranslateString>
                <span>({errorMessage})</span>
              </Alert>
            </div>
          )}

          <form className="bg-blue-100 text-center w-4/5 px-3 py-4  mx-auto rounded">
            <input
              type="text"
              placeholder="Email"
              className="block w-full mx-auto text-sm py-2 px-3 rounded"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              autocomplete="new-password"
              className="block w-full mx-auto text-sm py-2 px-3 rounded my-3"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder={T("Verify password")}
              className="block w-full mx-auto text-sm py-2 px-3 rounded my-3"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
            {!pass2Ok && "password don't match"}
            <Button
              className="mx-auto w-full"
              onClick={handleSignup}
              disabled={!formOk}>
              <TranslateString>Sign up</TranslateString>
            </Button>
            <p className="mt-5">
              Already have an account?
              <Button
                type="link"
                color="link"
                className=""
                onClick={handleLogin}>
                <TranslateString>Sign in</TranslateString>
              </Button>
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

// export const Register = connect(
//   mapStateToProps,
//   mapDispatchtoProps
// )(withRouter(__Register));
