import dayjs from "dayjs";

export const timestampToDate = (date) => {
  return new Date(date);
};

export const formatDate = (date) => {
  return new Date(date).toLocaleString([], {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });
};

export const lightOrDak = (color) => {
  let r, g, b, hsp;
  if (!color) {
    return "light";
  }
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "light";
  } else {
    return "dark";
  }
};

export const complementaryTextColor = (backgroundColor) => {
  switch (lightOrDak(backgroundColor)) {
    case "dark":
      return "#eee";
    case "light":
    default:
      return "#444";
  }
};

export const dayDiff = (d1, d2) => {
  return dayjs(d1)
    .hour(12)
    .minute(0)
    .second(0)
    .millisecond(0)
    .diff(dayjs(d2).hour(12).minute(0).second(0).millisecond(0), "d");
};

export const processItemTime = (item) => {
  const date = timestampToDate(item.date);
  const isPast = date < new Date();
  const dowString = dayjs(date).format("dddd");
  const dateString = date.toLocaleDateString();
  const timeString = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit"
  });
  return {
    date,
    isPast,
    dowString,
    dateString,
    timeString
  };
};

export const processItem = (item, auxData) => {
  if (!auxData) {
    // TODO: check why
    return {};
  }

  const date = timestampToDate(item.date);
  const isPast = date < new Date();
  const dowString = dayjs(date).format("dddd");
  const dateString = date.toLocaleDateString();
  const timeString = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit"
  });
  const activity = auxData.activities.find(
    (act) => act.id === item.activity_id
  );
  const activityName = activity?.name;
  const activityPlace = activity?.place;
  const itemTitle = [dowString, timeString, activityName, activityPlace]
    .filter((v) => v !== null)
    .join(", ");
  return {
    date,
    isPast,
    dowString,
    dateString,
    timeString,
    activityName,
    activityPlace,
    itemTitle
  };
};

export const nanoid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
