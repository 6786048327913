import React from "react";
import {Tag, Button, Label} from "../../components";
import {processItem} from "../../common/utils";
import {useSelector} from "react-redux";
import {TranslateString, useLangProvider} from "../../lang-provider";
import dayjs from "dayjs";

export const Item = ({
  item,
  showExtraInfo = false,
  omitButton = false,
  handleClick
}) => {
  const auxData = useSelector((state) => state.aux.data);

  const {T} = useLangProvider();

  if (!item) return "";

  const {
    isPast,
    dateString,
    activityName,
    activityPlace,
    timeString,
    dowString
  } = processItem(item, auxData);

  const {is_cancelled: isCancelled} = item;

  const lessonDate = dayjs(item.date);

  const tags = item.tags.map((tagId) =>
    auxData?.tags.find((t) => t.id === tagId)
  );
  const instructors = item.instructors.map((instructorId) =>
    auxData?.instructors.find((t) => t.id === instructorId)
  );

  const soldOut =
    item.num_places &&
    item.num_bookings > 0 &&
    item.num_bookings >= item.num_places;

  return (
    <div className="relative">
      <div
        className={`bg-gray-50 my-2 p-3 \
        flex justify-between items-center flex-row gap-5 ${
          isPast && "bg-gray-300 text-blue-900"
        }
        ${isCancelled && "line-through"}
        `}
        key={item.id}>
        {/* first col */}
        <div className="flex flex-col gap-2">
          <CalendarIcon lessonDate={lessonDate} isPast={isPast} />
        </div>
        {/* second col */}
        <div className="flex-grow flex flex-col gap-1">
          <div className="font-bold">
            {/* <Label>
            <TranslateString>What</TranslateString>
          </Label> */}
            {isCancelled && (
              <span>
                <TranslateString>CANCELLED</TranslateString>
              </span>
            )}
            {activityName} - {activityPlace}
          </div>
          <div>{timeString}</div>
          {!!tags.length && (
            <div>
              {tags.map((tag) => (
                <Tag key={tag?.name} tag={tag} />
              ))}
            </div>
          )}
          {showExtraInfo && (
            <>
              <hr className="my-2 bg-gray-200" />
              <p>
                Bookings: {item.num_bookings}/{item.num_places || "-"}
              </p>
              <p>
                Instructors:{" "}
                {instructors.map((instructor) => instructor?.name).join(", ")}
              </p>
              {/* <p>Is public?: {item.is_public ? "Yes" : "No"}</p> */}
            </>
          )}
        </div>
        {/* {isPast ? (
        <Button onClick={() => handleClick(item.id)}>See lesson notes</Button>
          ) : (
        <Button onClick={() => handleClick(item.id)}>Book now</Button>
      )} */}
        {!isPast && !omitButton && (
          <Button onClick={() => handleClick(item.id)} disabled={soldOut}>
            {T("Book now")}
          </Button>
        )}
      </div>
      {soldOut && (
        <div className="absolute top-0 right-0 bottom-0 w-24 flex justify-center items-center">
          <div className="border-4 border-white bg-red-500 p-2 transform -rotate-45 text-white font-bold uppercase text-xs">
            <TranslateString>Sold out!</TranslateString>
          </div>
        </div>
      )}
    </div>
  );
};

export const ActivityItem = ({
  activity,
  date,
  time,
  showExtraInfo = false,
  omitButton = false,
  handleClick,
  handleSelect
}) => {
  const {T} = useLangProvider();

  if (!activity) return "";

  const lessonDate = dayjs(date);

  const isPast = false;
  const isCancelled = false;
  const config = JSON.parse(activity.recurrent_config);

  return (
    <div className="relative">
      <div
        className={`bg-gray-50 my-2 p-3 \
        flex justify-between items-center flex-row gap-5 ${
          isPast && "bg-gray-300 text-blue-900"
        }
        ${isCancelled && "line-through"}
        `}
        key={activity.id}>
        {/* first col */}
        <div className="flex flex-col gap-2">
          <div className="text-center bg-red-200 filter drop-shadow-md">
            <div className="bg-red-500 text-white uppercase text-xs py-1 px-2">
              {lessonDate.format("MMM")}
            </div>
            <div className="bg-white px-4 py-1 font-bold">
              {lessonDate.date()}
            </div>
            <div className="bg-white px-4 py-1 text-xs">
              {lessonDate.format("ddd")}
            </div>
          </div>
        </div>
        {/* second col */}
        <div className="flex-grow flex flex-col gap-1">
          <div className="font-bold">
            {/* <Label>
            <TranslateString>What</TranslateString>
          </Label> */}
            {isCancelled && (
              <span>
                <TranslateString>CANCELLED</TranslateString>
              </span>
            )}
            {activity.name} - {activity.place}
          </div>
          <div>{time}</div>
        </div>
      </div>
    </div>
  );
};
const CalendarIcon = ({lessonDate, isPast}) => {
  return (
    <div className="text-center filter drop-shadow-md">
      <div
        className={`text-white uppercase text-xs py-1 px-2 ${
          !isPast ? "bg-red-500" : "bg-gray-500"
        }`}>
        {lessonDate.format("MMM")}
      </div>
      <div className="bg-white px-4 py-1 font-bold">{lessonDate.date()}</div>
      <div className="bg-white px-4 py-1 text-xs">
        {lessonDate.format("ddd")}
      </div>
    </div>
  );
};
