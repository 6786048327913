import React, {useEffect, useState} from "react";
import useFetch from "use-http";
import _ from "lodash";
import {Routes, Route, useParams, useLocation} from "react-router-dom";
import {Formik, Field, Form} from "formik";
import {useNavigate} from "react-router-dom";
import {API_ENDPOINT} from "../../config";
import {Button} from "../../components";
import {List, ListItem, ListFooter} from "./components";

const DEFAULT_SETTINGS = [
  {
    name: "whatsapp_url",
    label: "Whatsapp",
    description: "Whatsapp number (eg: 35191123123)"
  },
  {name: "twitter_url", label: "Twitter", description: "twitter"},
  {name: "instagram_url", label: "Instagram", description: "instagram"},
  {name: "facebook_url", label: "Facebook", description: "facebook"},
  {name: "terms_conditions", label: "T&C", description: "Terms and Conditions"},
  {name: "about_text", label: "About text", description: "School about text"},
  {
    name: "mail_extra_notes",
    label: "Extra notes",
    description: "Include in all emails"
  },
  {name: "home_alert", label: "Warning message", description: "Home alert"},
  {
    name: "enable_payments",
    label: "Enable payments",
    description: "Enable payments"
  }
];

const SettingsForm = ({onCreate, onUpdate, onDelete, data, path}) => {
  const [isDone, setIsDone] = useState(false);
  const {id, name} = useParams();

  const isNew = !id;
  const values =
    data.find((el) => (id && el.id === id) || el.name === name) || {};
  const updater = isNew ? onCreate : onUpdate;

  const navigate = useNavigate();

  if (isDone) {
    navigate("../");
    return <div />;
  }
  return (
    <div>
      <Formik
        initialValues={values}
        onSubmit={async (values) => {
          await updater(values);
          setIsDone(true);
        }}>
        <Form className="flex flex-col gap-2">
          {/* <label htmlFor="name">ID</label>
          <Field
            type="text"
            id="description"
            name="description"
            placeholder="description"
            autoComplete="off"
            readOnly
          /> */}
          <label htmlFor="name">{values.description}</label>
          {/* <label htmlFor="place">Value</label> */}
          <Field
            type="text"
            as="textarea"
            rows={10}
            id="value"
            name="value"
            placeholder=""
            autoComplete="off"
          />

          <Button type="submit">{isNew ? "Create" : "Update"}</Button>
          <Button onClick={() => setIsDone(true)}>Cancel</Button>
          {/* {!isNew && (
            <Button
              color="destructive"
              onClick={() => onDelete(id) && setIsDone(true)}>
              Delete
            </Button>
          )} */}
        </Form>
      </Formik>
    </div>
  );
};

export const Settings = (props) => {
  const [settings, setSettings] = useState([]);
  const navigate = useNavigate();
  let location = useLocation();

  const {get, post, put, response, loading, error} = useFetch(API_ENDPOINT, {
    cachePolicy: "no-cache"
  });

  async function loadInitialData() {
    const initialSettings = await get("/api/settings");
    const ini = _.keyBy(initialSettings, "name");
    var values = DEFAULT_SETTINGS.map((s) => ({
      ...s,
      ...ini[s.name]
    }));
    setSettings(_.clone(values));
  }

  useEffect(() => {
    loadInitialData();
  }, []);

  const formProps = {
    data: settings,
    initialValues: {},
    onCancel: () => navigate(-1),
    onCreate: async (values) => {
      const res = await post("/api/settings", values);
      return res;
    },
    onUpdate: async (values) => {
      const {id} = values;
      const res = await put(`/api/settings/${id}`, values);
      return res;
    },
    onDelete: async (id) => {
      alert("not implemented");
    }
  };

  return (
    <div className="flex flex-col gap-2">
      {error && "Error!"}
      {loading && "Loading..."}
      <div className="container safe-areas">
        <Routes>
          <Route path={`:id`} element={<SettingsForm {...formProps} />} />
          <Route path={`new/:name`} element={<SettingsForm {...formProps} />} />
          <Route
            path={``}
            element={
              <List>
                {settings.map((item, index) => (
                  <ListItem
                    key={`${item.name}${item.id}${item.value}`}
                    to={`${item.id || `new/${item.name}`}`}>
                    <strong>{item.label}</strong> {item.value || "-"}
                  </ListItem>
                ))}
                <ListFooter>{settings.length} items</ListFooter>
              </List>
            }
          />
        </Routes>
      </div>
    </div>
  );
};
