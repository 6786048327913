import React, {useState, useEffect} from "react";

import {fetchJson} from "api";
import {schedule} from "lang";

import {Alert} from "components/alert";
import {Button} from "components";

import {fetchLessonData, data} from "containers/manage/lessons";
import {config} from "config";
import {formatDate} from "common/utils";

export const notifyUsers = async () => {
  const url = `/api/notifications/send`;
  try {
    await fetchJson(url, {
      method: "POST"
    });
  } catch (e) {
    return false;
  }
  return true;
};

export const notifyUsersPush = async () => {
  const url = `/api/notifications/send-push`;
  try {
    await fetchJson(url, {
      method: "POST"
    });
  } catch (e) {
    return false;
  }
  return true;
};

const shareStuff = async (shareData) => {
  try {
    await navigator.share(shareData);
  } catch (error) {
    console.error(error);
  }
};

const getIframeExample = () => {
  return `<!-- add "nowtical-button" class to your booking cta(s) -->
<button class="nowtical-button">Book here</a>

<!-- add script tag to the end of your body tag -->
<script
  src="//nowtical.com/widget.js?v2"
  data-url="${config.shareableUrl}"
  defer></script>`;
};

export const Dashboard = () => {
  const [isNotified, setIsNotified] = useState(false);
  const [nextMessage, setNextMessage] = useState("");
  const [hasData, setHasData] = useState(false);
  const [stats, setStats] = useState({});

  const performNotification = async () => {
    const ok = await notifyUsers();
    if (ok) {
      setIsNotified(true);
      schedule(5000, () => setIsNotified(false));
    } else {
      alert("Oops, error");
    }
  };

  const performPushNotification = async () => {
    const ok = await notifyUsersPush();
    if (ok) {
      setIsNotified(true);
      schedule(5000, () => setIsNotified(false));
    } else {
      alert("Oops, error");
    }
  };

  const performShare = () => {
    const shareData = {
      title: "New lessons",
      text: nextMessage,
      url: config.shareableUrl
    };
    shareStuff(shareData);
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const data = await fetchLessonData();
    const {next} = []; //bucketData(data.lessons);
    setHasData(next && next.length > 0);
    const nextMessage = [
      "Próximas aulas:",
      ...next.map(
        (item) =>
          `- ${[
            formatDate(item.date),
            data.aux.activities[item.activity_id].name,
            data.aux.activities[item.activity_id].place
          ].join(", ")}`
      )
    ].join("\n");
    setNextMessage(nextMessage);
    setStats(data.aux.stats);
  };

  return (
    <div>
      {isNotified && <Alert>Notification sent</Alert>}

      <div className="bg-red-100 p-5 mb-2">
        <h2 className="font-bold mb-3">Welcome</h2>
      </div>
      <div className="bg-yellow-100 p-5 mb-2">
        <h2 className="font-bold mb-3">Sharing</h2>
        <div className="flex gap-2 grow-0">
          <Button disabled={!hasData} onClick={() => performNotification()}>
            Send email notification to subscribers
          </Button>
          <Button disabled={!hasData} onClick={() => performPushNotification()}>
            Send push notification
          </Button>
          <Button disabled={!hasData} onClick={() => performShare()}>
            Share to social media
          </Button>
        </div>
        {hasData && (
          <>
            <p className="my-2 uppercase text-xs">Example:</p>
            <div className="bg-white p-2 shadow">
              <pre className="text-xs">
                {nextMessage}
                {"\n"}→ {config.shareableUrl}
              </pre>
            </div>
          </>
        )}
      </div>
      <div className="bg-blue-100 p-5 mb-2">
        <h2 className="font-bold mb-3">Stats</h2>
        <div className="">
          <div className="">
            <p>
              Number of subscribers: {stats.subscribers} (email),{" "}
              {stats.push_subscribers} (push)
            </p>
          </div>
        </div>
      </div>
      <div className="bg-green-100 p-5 mb-2">
        <h2 className="font-bold mb-3">Docs</h2>

        <h3 className="my-2">Website integration for developers</h3>
        <code className=" ">
          <pre className="overflow-scroll bg-white text-xs p-1">
            {getIframeExample()}
          </pre>
        </code>

        <h3 className="my-2">Booking QR Code</h3>
        <p>
          <img
            width={150}
            alt={`qr code for ${config.shareableUrl}`}
            src={`//chart.googleapis.com/chart?cht=qr&choe=UTF-8&chs=300x300&chl=${config.shareableUrl}`}
          />
        </p>
      </div>
    </div>
  );
};
