import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import dayjs from "dayjs";

import {fetchPostsData} from "../../ducks/posts";

import {PageTitle} from "../../components";
import {TranslateString} from "../../lang-provider";

const renderPost = (post) => (
  <div className="my-4 py-6" key={post.id}>
    <div className="flex justify-between items-center">
      <span className="font-light text-gray-600">
        {dayjs(post.creation_date).toDate().toLocaleString({
          hour: "2-digit",
          minute: "2-digit"
        })}
      </span>
    </div>
    <div className="mt-2">
      <a
        className="text-2xl text-gray-700 font-bold hover:text-gray-600"
        href={`/post/${post.id}`}>
        {post.title}
      </a>
      <p className="mt-2 text-gray-600">{post.body}</p>
    </div>
  </div>
);

export const Post = (props) => {
  const {id} = useParams();
  const dispatch = useDispatch();

  const posts = useSelector((state) => state.posts.data);

  useEffect(() => {
    dispatch(fetchPostsData());
  }, [dispatch]);

  return (
    <React.Fragment>
      <PageTitle>
        <TranslateString>Posts</TranslateString>
      </PageTitle>
      {posts.filter((post) => post.id === id).map(renderPost)}
    </React.Fragment>
  );
};

export const Posts = (props) => {
  const dispatch = useDispatch();

  const posts = useSelector((state) => state.posts.data);

  useEffect(() => {
    dispatch(fetchPostsData());
  }, [dispatch]);

  return (
    <React.Fragment>
      <PageTitle>
        <TranslateString>Posts</TranslateString>
      </PageTitle>
      {posts.map(renderPost)}
    </React.Fragment>
  );
};
