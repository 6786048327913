import produce from "immer";
import validator from "validator";

import {fetchJson} from "../api";
import {loadState, saveState} from "../common/localStorageHelpers";
// import {loadingPush, loadingPop} from "./loading";

const userTemplate = {name: "", id: "", age: ""};

const KEY = "contact-info";
const PENDING_KEY = "pending-booking-info";

const validate = (data) => {
  const atLeast1 = data.users.length > 0;
  const hasNames = data.users.reduce((prev, curr, currentIndex, array) => {
    return prev && !!curr.name;
  }, true);
  const hasEmail = validator.isEmail(data.email || "");
  const hasPhone = validator.isMobilePhone(data.phone || "");
  return atLeast1 && hasNames && hasEmail && hasPhone;
};

// actions
const BOOKING_INIT = "BOOKING_INIT";
const BOOKING_ITEM_ADD = "BOOKING_ITEM_ADD";
const BOOKING_ITEM_DELETE = "BOOKING_ITEM_DELETE";
const BOOKING_ITEM_UPDATE = "BOOKING_ITEM_UPDATE";
const BOOKING_NOTES_SET = "BOOKING_NOTES_SET";
const BOOKING_EMAIL_SET = "BOOKING_EMAIL_SET";
const BOOKING_PHONE_SET = "BOOKING_PHONE_SET";
const BOOKING_TIME_SET = "BOOKING_TIME_SET";
const BOOKING_SUBSCRIBE_SET = "BOOKING_SUBSCRIBE_SET";
const BOOKING_SUBMIT = "BOOKING_SUBMIT";
const BOOKING_SUBMIT_OK = "BOOKING_SUBMIT_OK";
const BOOKING_SUBMIT_OK2 = "BOOKING_SUBMIT_OK2";
const BOOKING_SUBMIT_ERR = "BOOKING_SUBMIT_ERR";

const BOOKING_DATA_LOAD = "BOOKING_DATA_LOAD";
const BOOKING_DATA_OK = "BOOKING_DATA_OK";
const BOOKING_DATA_ERR = "BOOKING_DATA_ERR";

const BOOKING_TOTAL_AMOUNT_LOAD = "BOOKING_TOTAL_AMOUNT_LOAD";
const BOOKING_TOTAL_AMOUNT_OK = "BOOKING_TOTAL_AMOUNT_OK";
const BOOKING_TOTAL_AMOUNT_ERR = "BOOKING_TOTAL_AMOUNT_ERR";

const initialState = {
  data: {
    users: [{...userTemplate}],
    notes: "",
    email: "",
    phone: "",
    ...loadState(KEY),
    lesson: null,
    subscribe: false
  },
  booking: null,
  valid: false,
  loading: false,
  error: false,
  submitted: false,
  submitToStripe: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_INIT:
      return produce(state, (draft) => {
        draft.error = false;
        draft.submitted = false;
        draft.submitToStripe = false;
        draft.data.lesson = action.payload?.lesson;
        draft.data.recur = action.payload?.recur;
        draft.data.name = action.payload?.user?.name;
        draft.data.email = action.payload?.user?.email || state.data.email;
        draft.data.activityId = action.payload?.activityId;
        draft.valid = validate(draft.data);
      });
    case BOOKING_ITEM_ADD:
      return produce(state, (draft) => {
        draft.data.users.push(userTemplate);
        draft.valid = validate(draft.data);
      });
    case BOOKING_ITEM_DELETE:
      return produce(state, (draft) => {
        draft.data.users = state.data.users.filter(
          (el, idx) => idx !== action.payload
        );
        draft.valid = validate(draft.data);
      });
    case BOOKING_ITEM_UPDATE:
      return produce(state, (draft) => {
        const {name, index, value} = action.payload;
        draft.data.users[index][name] = value;
        draft.valid = validate(draft.data);
      });
    case BOOKING_NOTES_SET:
      return produce(state, (draft) => {
        draft.data.notes = action.payload;
        draft.valid = validate(draft.data);
      });
    case BOOKING_EMAIL_SET:
      return produce(state, (draft) => {
        draft.data.email = action.payload;
        draft.valid = validate(draft.data);
      });
    case BOOKING_PHONE_SET:
      return produce(state, (draft) => {
        draft.data.phone = action.payload;
        draft.valid = validate(draft.data);
      });
    case BOOKING_SUBSCRIBE_SET:
      return produce(state, (draft) => {
        draft.data.subscribe = action.payload;
        draft.valid = validate(draft.data);
      });
    case BOOKING_SUBMIT:
      return produce(state, (draft) => {
        const {email, phone, notes, users} = state.data;
        saveState(KEY, {email, phone, notes, users});
        draft.loading = true;
      });
    case BOOKING_SUBMIT_OK:
      return produce(state, (draft) => {
        draft.submitted = true;
        draft.loading = false;
        draft.error = false;
      });
    case BOOKING_SUBMIT_OK2:
      return produce(state, (draft) => {
        // draft.submitted = true;
        draft.submitToStripe = true;
        draft.loading = false;
        draft.error = false;
      });
    case BOOKING_SUBMIT_ERR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = true;
      });
    // existing booking
    case BOOKING_DATA_LOAD:
      return produce(state, (draft) => {
        draft.error = false;
        draft.loading = true;
        draft.booking = null;
      });
    case BOOKING_DATA_OK:
      return produce(state, (draft) => {
        // draft.data = action.data;
        draft.booking = action.payload;
        draft.loading = false;
      });
    case BOOKING_DATA_ERR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = true;
      });
    case BOOKING_TOTAL_AMOUNT_LOAD:
      return produce(state, (draft) => {
        draft.totalAmount = null;
      });
    case BOOKING_TOTAL_AMOUNT_OK:
      return produce(state, (draft) => {
        draft.totalAmount = action.payload.amount;
      });
    case BOOKING_TOTAL_AMOUNT_ERR:
      return produce(state, (draft) => {
        draft.totalAmount = "err";
      });
    default:
      return state;
  }
};

// action creators
export const setInitialBookinData = (payload) => ({
  type: BOOKING_INIT,
  payload
});
export const addBookingItem = () => ({type: BOOKING_ITEM_ADD});
export const deleteBookingItem = (payload) => ({
  type: BOOKING_ITEM_DELETE,
  payload
});
export const updateBookingItem = (payload) => ({
  type: BOOKING_ITEM_UPDATE,
  payload
});
export const updateNotes = (payload) => ({type: BOOKING_NOTES_SET, payload});
export const updateEmail = (payload) => ({type: BOOKING_EMAIL_SET, payload});
export const updatePhone = (payload) => ({type: BOOKING_PHONE_SET, payload});
export const updateTime = (payload) => ({type: BOOKING_TIME_SET, payload});
export const updateSubscribe = (payload) => ({
  type: BOOKING_SUBSCRIBE_SET,
  payload
});
export const submitData = () => ({type: BOOKING_SUBMIT});
export const submitDataSuccess = () => ({type: BOOKING_SUBMIT_OK});
export const submitDataSuccess2 = () => ({type: BOOKING_SUBMIT_OK2});
export const submitDataFailure = () => ({type: BOOKING_SUBMIT_ERR});

export const getBooking = () => ({type: BOOKING_DATA_LOAD});
export const getBookingSuccess = (payload) => ({
  type: BOOKING_DATA_OK,
  payload
});
export const getBookingFailure = () => ({type: BOOKING_DATA_ERR});

export const getBookingTotalAmount = () => ({type: BOOKING_TOTAL_AMOUNT_LOAD});
export const getBookingTotalAmountSuccess = (payload) => ({
  type: BOOKING_TOTAL_AMOUNT_OK,
  payload
});
export const getBookingTotalAmountFailure = () => ({
  type: BOOKING_TOTAL_AMOUNT_ERR
});

export const clearPendingBookingData = () => async () => {
  saveState(PENDING_KEY, {});
};

export const submitPendingBookingData = (sessionId) => async () => {
  try {
    const {url, data} = loadState(PENDING_KEY);
    if (url && data) {
      const newData = {...data};
      newData.body.stripe_session_id = sessionId;
      const response = await fetchJson(url, newData);
      console.log(response);
      saveState(PENDING_KEY, {});
    }
  } catch (e) {
    console.log(e);
  }
};

export const submitBookingData =
  (lang, paymentIntentId) => async (dispatch, getState) => {
    dispatch(submitData());

    const state = getState();
    const {users, lesson, phone, email, notes, recur} = state.booking.data;
    const user = state.user.data;

    try {
      let response;
      if (lesson) {
        const url = `/api/lessons/${lesson.id}/bookings?lang=${lang}`;
        const data = {
          method: "POST",
          body: {
            users,
            phone,
            email,
            notes,
            user_id: user?.id || "0",
            lesson,
            payment_intent_id: paymentIntentId
          }
        };

        if (!paymentIntentId) {
          // submit booking only
          response = await fetchJson(url, data);
        } else {
          saveState(PENDING_KEY, {url, data});
          // return;
        }
      } else if (recur) {
        const url = `/api/activity/${recur.id}/bookings`;
        response = await fetchJson(url, {
          method: "POST",
          body: {
            users,
            phone,
            email,
            notes,
            user_id: user?.id || "0",
            lesson,
            recur
          }
        });
      }

      // no payment ID? set submitted flag to display success screen, if not, do nothting and wait for stripe
      // FIX this properly
      const setSubmitted = !paymentIntentId;
      if (setSubmitted) {
        // if !paymentIntentId success is shown after coming from stripe
        // if payment id, don't show success message yet (safari race condition)
        dispatch(submitDataSuccess(setSubmitted));
      } else {
        dispatch(submitDataSuccess2(setSubmitted));
      }
    } catch (error) {
      // hack! form onsubmit will fail on mac/ios safari while submitting to stripe
      // if (!paymentIntentId) {
      alert(error);
      dispatch(submitDataFailure());
      // }
    }
  };

export function fetchBookingData(id) {
  return async (dispatch) => {
    dispatch(getBooking());
    // dispatch(loadingPush());
    try {
      const response = await fetchJson(`/api/bookings/${id}`);
      dispatch(getBookingSuccess(response));
    } catch (error) {
      dispatch(getBookingFailure());
    }
    // dispatch(loadingPop());
  };
}

export function cancelBooking(id) {
  return async (dispatc) => {
    try {
      await fetchJson(`/api/bookings/${id}`, {method: "DELETE"});
      alert("OK!");
    } catch (error) {}
  };
}

export const fetchTotalAmount = (items) => {
  return async (dispatch) => {
    dispatch(getBookingTotalAmount());
    try {
      const response = await fetchJson(`/api/calc-total-amount`, {
        method: "POST",
        body: {items}
      });
      dispatch(getBookingTotalAmountSuccess(response));
    } catch (error) {
      dispatch(getBookingTotalAmountFailure());
    }
  };
};

export default reducer;
