import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {PageTitle} from "../../components";
import {TranslateString} from "../../lang-provider";
import {fetchProductsData} from "../../ducks/products";

export function Pricing() {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.products.data);

  useEffect(() => {
    dispatch(fetchProductsData());
  }, [dispatch]);

  return (
    <React.Fragment>
      <PageTitle>
        <TranslateString>Pricing</TranslateString>
      </PageTitle>
      <table className="table-auto border-collapse w-full border">
        <thead>
          <tr className="rounded-lg text-sm font-medium text-gray-700 bg-gray-100">
            <th className="px-4 py-2 text-left">
              <TranslateString>Item</TranslateString>
            </th>
            <th className="px-4 py-2 text-center" colSpan={2}>
              <TranslateString>Price</TranslateString>
            </th>
          </tr>
        </thead>
        <tbody className="text-sm font-normal text-gray-700">
          {items.map((item) => (
            <tr className="border-b border-gray-100 py-10">
              <td className="px-4 py-4 text-left">
                {item.title}
                {item.description && (
                  <p className="mt-2 text-xs text-gray-500">
                    {item.description}
                  </p>
                )}
              </td>
              <td className="px-4 py-4 text-center">{item.price / 100}€</td>
              {/* <td className="px-4 py-4 text-center">
                <button>Buy</button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  );
}
