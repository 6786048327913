import {useState} from "react";
import {Transition} from "@headlessui/react";
import {useOuterClick, useLocationChange} from "../common/hooks";

export const AnimatedDropdown = ({
  children,
  position = "origin-top-right right-0",
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const dismiss = () => setOpen(false);

  // dismiss on outer click and location change
  const innerRef = useOuterClick(dismiss);
  useLocationChange(dismiss);

  return (
    <div
      ref={innerRef}
      className={`relative inline-block text-left ${props.className}`}>
      <div>{props.anchor(open, setOpen)}</div>
      <Transition
        show={open}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95">
        {(ref) => (
          <div
            ref={ref}
            className={`${position} absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50`}>
            <div className="flex flex-col gap-5 p-5 text-sm">
              {/* pass dismiss if click handlers do not trigger navigation */}
              {typeof children === "function" ? children(dismiss) : children}
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};
