import React, {useState, useEffect} from "react";
import {TranslateString} from "../lang-provider";
import {PageTitle} from "../components";

import {fetchJson} from "api";
import {useDispatch} from "react-redux";
import {clearPendingBookingData, submitPendingBookingData} from "ducks/booking";

const Message = ({message}) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function PaymentConfirm(props) {
  const [message, setMessage] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [paymentId, setPaymentId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Payment processed successfully. Thank you ❤️");
      setSessionId(query.get("session_id"));
      setPaymentId(query.get("id"));
    }
    if (query.get("canceled")) {
      setMessage("Payment canceled, please try again");
      dispatch(clearPendingBookingData());
    }
  }, []);

  useEffect(() => {
    // mark payment
    if (!sessionId || !paymentId) {
      return;
    }
    dispatch(submitPendingBookingData(sessionId));
    // const url = `/api/confirm-booking-payment`;
    // fetchJson(url, {
    //   method: "POST",
    //   body: {
    //     sessionId,
    //     paymentId
    //   }
    // }).then((res) => {
    //   console.log({res});
    // });
  }, [sessionId, paymentId]);

  return (
    <React.Fragment>
      <PageTitle>
        <TranslateString>Booking confirmation</TranslateString>
      </PageTitle>
      <div className="card p-4 text-center">
        <div>
          <TranslateString></TranslateString>
          {message ? <Message message={message} /> : "oops?!?"}
        </div>
        <div className="mt-10">
          <a href="/" className="btn">
            Start again
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
