import {AnimatedDropdown} from "./animated-dropdown";

export const LanguageSelector = ({setLang, lang, langs, descriptions}) => {
  return (
    <AnimatedDropdown
      anchor={(open, setOpen) => (
        <button
          className="inline-flex gap-2 justify-center items-center w-full h-8 px-2 rounded-md border border-gray-300 shadow-sm bg-white text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 text-xs font-bold"
          type="button"
          id="options-menu"
          aria-haspopup="true"
          onClick={() => setOpen(!open)}>
          {descriptions[lang]}
        </button>
      )}>
      {(dismiss) =>
        langs.map((l) => (
          <div
            role="button"
            className="text-xs"
            key={l}
            value={l}
            onClick={() => {
              setLang(l);
              dismiss();
            }}>
            {descriptions[l]}
          </div>
        ))
      }
    </AnimatedDropdown>
  );
};
