import produce from "immer";
import _ from "lodash";

import {fetchJson} from "../api";
import {loadingPush, loadingPop} from "./loading";

// actions
const AUX_DATA_LOAD = "AUX_DATA_LOAD";
const AUX_DATA_OK = "AUX_DATA_OK";
const AUX_DATA_ERR = "AUX_DATA_ERR";

const initialState = {
  data: {tags: [], instructors: [], activities: [], settings: {}},
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUX_DATA_LOAD:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case AUX_DATA_OK:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.data = action.data;
      });
    case AUX_DATA_ERR:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    default:
      return state;
  }
};

// Action Creators
export const loadAuxData = () => ({
  type: AUX_DATA_LOAD
});

export const auxDataUpdate = (data) => ({type: AUX_DATA_OK, data});

export const auxDataFailed = () => ({type: AUX_DATA_ERR});

export const fetchAuxData = () => {
  return async (dispatch) => {
    dispatch(loadingPush());
    dispatch(loadAuxData());
    try {
      const response = await Promise.all([
        fetchJson("/api/tags"),
        fetchJson("/api/instructors"),
        fetchJson('/api/activities?sort=["order_num","ASC"]'),
        fetchJson("/api/settings")
      ]);
      const [tags, instructors, activities, settings] = response;
      dispatch(
        auxDataUpdate({
          tags,
          instructors,
          activities,
          settings: _.keyBy(settings, "name")
        })
      );
    } catch (e) {
      dispatch(auxDataFailed());
    }
    dispatch(loadingPop());
  };
};

export default reducer;
