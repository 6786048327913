import {useSelector} from "react-redux";
import {TranslateString} from "../lang-provider";
import {FaFacebook, FaInstagram, FaTwitter, FaWhatsapp} from "react-icons/fa";

export const SocialMedia = (props) => {
  const settings = useSelector((state) => state.aux?.data?.settings);

  const data = [
    {href: settings?.facebook_url?.value, icon: <FaFacebook />},
    {href: settings?.instagram_url?.value, icon: <FaInstagram />},
    {href: settings?.twitter_url?.value, icon: <FaTwitter />},
    {
      href:
        settings?.whatsapp_url?.value &&
        `https://wa.me/${settings.whatsapp_url.value}`,
      icon: <FaWhatsapp />
    }
  ].filter(({href}) => !!href);

  if (!data.length) {
    return "";
  }

  return (
    <div className="text-center">
      <TranslateString>Follow us</TranslateString>
      <div className="my-2 flex gap-2 justify-center">
        {data.map((item) => (
          <a
            key={item.href}
            target="_blank"
            rel="noreferrer"
            href={item.href}
            className="text-2xl">
            {item.icon}
          </a>
        ))}
      </div>
    </div>
  );
};
