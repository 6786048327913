import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";
import {useSearchParams} from "react-router-dom";
import {Alert, Button} from "../components";
import {MdRemoveCircle} from "react-icons/md";

import {CheckBox} from "../components";

import {processItem} from "../common/utils";
import {PageTitle, Label} from "../components";
import {useLangProvider, TranslateString} from "../lang-provider";
import {
  setInitialBookinData,
  addBookingItem,
  deleteBookingItem,
  updateBookingItem,
  updateNotes,
  updateEmail,
  updatePhone,
  updateSubscribe,
  submitBookingData,
  fetchTotalAmount
} from "../ducks/booking";
import {fetchLessonsData} from "../ducks/lessons";
import {fetchAuxData} from "../ducks/aux";
import {Item, ActivityItem} from "./lessons/lesson-list-item";
import {nanoid} from "../common/utils";
import {API_ENDPOINT} from "../config";
import {useFetch} from "./use-fetch";
import {Loading} from "../components";
import {SmallLoading} from "components/loading";

const useLessons = () => {
  const [searchParams] = useSearchParams();
  const activityId = searchParams.get("activity");
  const dispatch = useDispatch();
  const {data, auxData} = useSelector((state) => ({
    data: state.lessons.data,
    auxData: state.aux.data
  }));

  const {id} = useParams();
  const item = data ? data.find((d) => d.id === id) : null;

  const activity = auxData.activities.find((act) => act.id === activityId);

  useEffect(() => {
    if (!item) {
      dispatch(fetchLessonsData());
      dispatch(fetchAuxData());
    }
  }, [item, id, dispatch]);

  return {
    lesson: item,
    activity,
    processedLesson: item && auxData ? processItem(item, auxData) : {},
    enablePayments: auxData.settings.enable_payments?.value === "1"
  };
};

export const BookFormStep1 = (props) => {
  const user = useSelector((state) => state.user.data);
  const navigate = useNavigate();
  const {id} = useParams();
  const [searchParams] = useSearchParams();

  // recur, along with id
  const time = searchParams.get("time");
  const activityId = searchParams.get("activity");

  const {lesson, activity} = useLessons();

  const dispatch = useDispatch();
  const data = useSelector((state) => ({
    users: state.booking.data.users,
    notes: state.booking.data.notes,
    phone: state.booking.data.phone,
    email: state.booking.data.email,
    subscribe: state.booking.data.subscribe,
    isValid: state.booking.valid
  }));

  useEffect(() => {
    dispatch(
      // setInitialBookinData({user: user, lesson: lesson, recur, activityId})
      setInitialBookinData({user: user, lesson: lesson})
    );
  }, [user, lesson, dispatch, id, time, activityId]);

  const {lang, T} = useLangProvider();

  return (
    <div className="">
      <PageTitle backTitle={T("Back")} />

      {lesson && <Item item={lesson} omitButton={true} />}
      {activity && <ActivityItem activity={activity} date={id} time={time} />}

      {data.users.map((p, index) => (
        <div key={index} className="my-5">
          <div className="flex justify-start items-center gap-2 py-2">
            <span className="font-bold">
              {T("Participant")} #{index + 1}
            </span>
            <span className="flex-grow" />
            {data.users.length > 1 ? (
              <Button
                type="small"
                color="destructive"
                onClick={() => {
                  dispatch(deleteBookingItem(index));
                }}>
                <MdRemoveCircle />
              </Button>
            ) : null}
            {/* <Button type="small" onClick={() => dispatch(addBookingItem())}>
              <MdAddCircle />
            </Button> */}
          </div>

          <div className="mb-2 flex gap-2">
            <input
              className="flex-grow"
              type="text"
              placeholder={T("Name")}
              value={p.name}
              onChange={(event) =>
                dispatch(
                  updateBookingItem({
                    name: "name",
                    index,
                    value: event.target.value
                  })
                )
              }
            />
            <input
              className="w-1/6"
              type="text"
              value={p.age}
              placeholder={T("Age")}
              onChange={(event) =>
                dispatch(
                  updateBookingItem({
                    name: "age",
                    index,
                    value: event.target.value
                  })
                )
              }
            />
          </div>
        </div>
      ))}

      <div className="flex justify-center items-center gap-2 mb-5">
        <Button type="small" onClick={() => dispatch(addBookingItem())}>
          <TranslateString>Add participant</TranslateString>
        </Button>
      </div>

      <div className="flex flex-col gap-2">
        <span className="font-bold">
          <TranslateString>Contacts</TranslateString>
        </span>
        <input
          className="w-full border border-gray-400"
          type="email"
          value={data.email}
          placeholder={T("E-mail")}
          onChange={(event) => {
            dispatch(updateEmail(event.target.value));
          }}
        />
        <input
          className="w-full border border-gray-400"
          type="tel"
          placeholder={T("Phone number")}
          value={data.phone}
          onChange={(value) => {
            dispatch(updatePhone(value.target.value));
          }}
        />
        <textarea
          className="w-full"
          placeholder={T("Extra notes")}
          defaultValue={data.notes}
          onChange={(event) =>
            dispatch(updateNotes(event.target.value))
          }></textarea>

        <CheckBox
          checked={data.subscribe}
          onChange={(e) => dispatch(updateSubscribe(e.target.checked))}
          label={T("Subscribe to new classes email notifications")}
        />
      </div>
      <Button
        className="mt-5 w-full"
        disabled={!data.isValid}
        // onClick={() => navigate(`/lessons/${id}/confirm`)}>
        onClick={props.handleNext}>
        <TranslateString>Continue</TranslateString>
      </Button>
    </div>
  );
};

export const BookFormStep2 = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {post} = useFetch(API_ENDPOINT);
  const {lang, T} = useLangProvider();

  const [clientSecret, setClientSecret] = React.useState("");
  const [submitStripe, setSubmitStripe] = React.useState(false);
  const [stripeLoading, setStripeLoading] = React.useState(false);
  const formRef = React.useRef(null);

  const {lesson, enablePayments} = useLessons();

  const {
    users,
    notes,
    phone,
    email,
    subscribe,
    isSubmitted,
    isError,
    loading,
    activityId,
    submitToStripe,
    totalAmount
  } = useSelector((state) => ({
    users: state.booking.data.users,
    notes: state.booking.data.notes,
    phone: state.booking.data.phone,
    email: state.booking.data.email,
    subscribe: state.booking.data.subscribe,
    isSubmitted: state.booking.submitted,
    submitToStripe: state.booking.submitToStripe,
    isError: state.booking.error,
    loading: state.booking.loading,
    activityId: state.booking.data.activityId,
    totalAmount: state.booking.totalAmount
  }));

  const [paymentId, setPaymentId] = useState(null);

  const items = JSON.stringify([
    {id: lesson.activity_id, quantity: users.length}
  ]);

  useEffect(() => {
    // generated pseudo-payment-id
    setPaymentId(nanoid());
  }, []);

  useEffect(() => {
    if (items) {
      dispatch(fetchTotalAmount(items));
    }
  }, [items]);

  useEffect(() => {
    if (submitToStripe) {
      formRef.current.submit();
    }
  }, [submitToStripe]);

  return (
    <div className="">
      {loading || stripeLoading ? <Loading /> : null}
      <PageTitle backTitle={T("Back")}>
        <TranslateString>Booking summary</TranslateString>
      </PageTitle>
      <Item item={lesson} omitButton={true} />
      <div className="bg-gray-50 hover:bg-gray-100 my-2 p-3 flex flex-col justify-between gap-5">
        {phone && (
          <div>
            <Label>
              <TranslateString>Phone</TranslateString>
            </Label>
            {phone}
          </div>
        )}
        {email && (
          <div>
            <Label>
              <TranslateString>Email</TranslateString>:
            </Label>
            {email}
          </div>
        )}
        {notes && (
          <div>
            <Label>
              <TranslateString>Notes</TranslateString>:
            </Label>
            {notes}
          </div>
        )}
        {subscribe && (
          <div>
            <Label>
              <TranslateString>
                Subscribe to new classes email notifications
              </TranslateString>
              :
            </Label>
            ✅
          </div>
        )}

        <div>
          <Label>
            <TranslateString>Users</TranslateString> ({users.length}):
          </Label>
        </div>

        <ul>
          {users.map((row, index) => (
            <li className="list-decimal list-inside" key={index}>
              {row.name} ({row.age || "-"})
            </li>
          ))}
        </ul>
      </div>

      {enablePayments && (
        <div className="bg-gray-50 hover:bg-gray-100 my-2 p-3 flex flex-col justify-between gap-5">
          <div className="flex justify-between items-center">
            <Label>
              <TranslateString>Total amount</TranslateString>:
            </Label>
            {totalAmount ? (
              <span className="font-bold">€{totalAmount}</span>
            ) : (
              <>
                - <SmallLoading />
              </>
            )}
          </div>
        </div>
      )}

      {!isSubmitted ? (
        <div className="mt-5 flex flex-col gap-2">
          {enablePayments ? (
            <>
              <form
                ref={formRef}
                action={`${API_ENDPOINT}/api/create-checkout-session`}
                method="POST"
                onSubmit={(e) => {
                  setStripeLoading(true);
                  if (!submitStripe) {
                    e.preventDefault();
                    dispatch(submitBookingData(lang, paymentId));
                    setSubmitStripe(true);
                  }
                }}>
                <input type="hidden" name="items" value={items} />
                <input type="hidden" name="paymentId" value={paymentId} />
                <button className="btn w-full" type="submit">
                  <TranslateString>Pay now</TranslateString>
                </button>
              </form>
              <Button
                className="mt-5 w-full"
                color="secondary"
                disabled={loading}
                onClick={() => {
                  dispatch(submitBookingData(lang));
                }}>
                {T("Pay later")}
              </Button>
            </>
          ) : (
            <Button
              className="mt-5 w-full"
              color="secondary"
              disabled={loading}
              onClick={() => {
                dispatch(submitBookingData(lang));
              }}>
              {T("Book now and pay later")}
            </Button>
          )}

          <Button
            className="mt-5 w-full"
            color="muted"
            onClick={() => props.handlePrev()}>
            {T("Edit")}
          </Button>
          <p>
            ✅{" "}
            <TranslateString>Agree with terms and conditions</TranslateString>
          </p>
          {isError && <Alert type="error">Error, try again</Alert>}
        </div>
      ) : (
        <Alert type="info">
          <TranslateString>Submitted, please check your email.</TranslateString>
        </Alert>
      )}
    </div>
  );
};

const steps = [BookFormStep1, BookFormStep2];

export const BookForm = (props) => {
  const [stepNumber, setStepNumber] = useState(0);
  const Comp = steps[stepNumber];
  return (
    <div>
      <Comp
        {...props}
        handleNext={() => setStepNumber(stepNumber + 1)}
        handlePrev={() => setStepNumber(stepNumber - 1)}
      />
    </div>
  );
};
