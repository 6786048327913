import React, {useEffect, useState} from "react";
import {
  Routes,
  Route,
  useParams,
  useLocation,
  useOutletContext
} from "react-router-dom";
import {Formik, Field, Form, ErrorMessage, FieldArray} from "formik";
import {useNavigate} from "react-router-dom";

import {useFetch} from "containers/use-fetch";
import {API_ENDPOINT} from "../../config";
import {Button} from "../../components";
import {
  List,
  ListItem,
  CTALink,
  ListFooter,
  ErrorsAndLoading,
  Label,
  Summary
} from "./components";

const CURRENT_PATH = "/manage/activities";
const ENABLE_RECURRENT = false;
const DAYS = "Mon Tue Wed Thu Fri Sat Sun".split(" ");

const ActivityForm = ({
  onCreate,
  onUpdate,
  onDelete,
  onDone,
  onCancel,
  initialValues,
  data,
  isNew
}) => {
  const {id} = useParams();
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (id) {
      const vals = data.find((item) => item.id === id);
      vals.recurrent_config = JSON.parse(vals.recurrent_config);
      setFormValues(vals);
    }
  }, []);

  return (
    <div className="">
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={async (values) => {
          const updater = isNew ? onCreate : onUpdate;
          await updater(values);
          onDone();
        }}>
        {({values}) => (
          <Form className="flex flex-col gap-2">
            <Label htmlFor="name">Name</Label>
            <Field
              type="text"
              id="name"
              name="name"
              placeholder="name"
              autoComplete="off"
            />
            <Label htmlFor="place">Place</Label>
            <Field
              type="text"
              id="place"
              name="place"
              placeholder="place"
              autoComplete="off"
            />
            <Label htmlFor="price">Price (€)</Label>
            <Field
              type="number"
              min="1"
              step="any"
              id="price"
              name="price"
              placeholder="price"
              autoComplete="off"
            />
            <Label htmlFor="order_num">Position</Label>
            <Field
              id="order_num"
              name="order_num"
              as="select"
              parse={(rawValue) => parseInt(rawValue, 10)}>
              <option value=""></option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="999">999</option>
            </Field>

            {ENABLE_RECURRENT && (
              <>
                <Label htmlFor="recurrent">Allow recurrent bookings</Label>
                <Field
                  name="recurrent"
                  render={({field}) => (
                    <>
                      <div className="radio-item">
                        <input
                          {...field}
                          id="no"
                          value="0"
                          name="recurrent"
                          checked={field.value == 0}
                          type="radio"
                        />
                        <label htmlFor="no">No</label>
                      </div>
                      <div className="radio-item">
                        <input
                          {...field}
                          id="yes"
                          value="1"
                          checked={field.value == 1}
                          disabled={true}
                          name="recurrent"
                          type="radio"
                        />
                        <label htmlFor="yes">Yes (N/A)</label>
                      </div>
                    </>
                  )}
                />
                {values.recurrent == 1 && (
                  <div className="border-l-[4px] pl-2 flex flex-col gap-5">
                    <Label htmlFor="place">Days</Label>
                    <div
                      role="group"
                      aria-labelledby="checkbox-group"
                      className="flex content-justify gap-2">
                      {DAYS.map(($, index) => (
                        <label className="">
                          <Field
                            type="checkbox"
                            name={`recurrent_config.days`}
                            value={$.toLowerCase()}
                          />
                          <span className="ml-1">{$}</span>
                        </label>
                      ))}
                    </div>
                    <FieldArray name="recurrent_config.times">
                      {({insert, remove, push}) => (
                        <>
                          <Label>
                            Per day
                            <button
                              type="button"
                              className="bg-gray-200 rounded px-2"
                              onClick={() => push({name: ""})}>
                              ➕ Add time
                            </button>
                          </Label>
                          <div className="flex flex-col gap-1">
                            {values.recurrent_config.times &&
                              values.recurrent_config.times.map(
                                (friend, index) => (
                                  <div
                                    className="flex items-center gap-1"
                                    key={index}>
                                    <>
                                      <label
                                        htmlFor={`recurrent_config.times.${index}.name`}>
                                        Time
                                      </label>
                                      <Field
                                        name={`recurrent_config.times.${index}.name`}
                                        placeholder="Morning / Afternoon"
                                        type="text"
                                      />
                                      <ErrorMessage
                                        name={`recurrent_config.times.${index}.name`}
                                        component="div"
                                        className="field-error"
                                      />
                                    </>
                                    {/* add other fields here */}
                                    <div className="col">
                                      <button
                                        type="button"
                                        className="secondary"
                                        onClick={() => remove(index)}>
                                        ❌
                                      </button>
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                          <Label htmlFor="place">Exceptions</Label>
                          ...
                        </>
                      )}
                    </FieldArray>
                  </div>
                )}
              </>
            )}
            <Button type="submit">{isNew ? "Create" : "Update"}</Button>
            <Button onClick={onCancel}>Cancel</Button>
            {!isNew && (
              <Button
                color="destructive"
                onClick={() => window.confirm("Confirm?") && onDelete(id)}>
                Delete
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

const ActivityList = ({activities}) => {
  return (
    <List>
      <ListFooter>
        <Summary>{activities && activities.length} items</Summary>
        <CTALink to="new">+ Add new</CTALink>
      </ListFooter>
      {activities &&
        activities.map((activity, index) => (
          <ListItem key={activity.id} to={activity.id}>
            {activity.order_num ? `${activity.order_num}. ` : ""}
            {activity.name} - {activity.place} (€ {activity.price})
          </ListItem>
        ))}
    </List>
  );
};

export const Activities = (props) => {
  const [dirty, setDirty] = useState(false);
  const [activities, setActivities] = useState([]);
  const navigate = useNavigate();

  const {get, post, put, del, loading, error} = useFetch(API_ENDPOINT);

  async function loadInitialData() {
    const d = await get(`/api/activities?sort=["order_num","ASC"]`);
    setActivities(d);
    setDirty(false);
  }

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    if (dirty) {
      loadInitialData();
    }
  }, [dirty]);

  const initialValues = {
    name: "",
    color: "#0000ff",
    order_num: "999",
    price: "0",
    recurrent: 0,
    recurrent_config: {
      days: ["mon", "tue", "wed", "thu", "fri"],
      times: [{name: "default"}]
    }
  };

  const formProps = {
    data: activities,
    initialValues,
    onCreate: async (values) => {
      await post("/api/activities", {
        ...values,
        recurrent_config: JSON.stringify(values.recurrent_config)
      });
      setDirty(true);
    },
    onUpdate: async (values) => {
      await put(`/api/activities/${values.id}`, {
        ...values,
        recurrent_config: JSON.stringify(values.recurrent_config)
      });
      setDirty(true);
    },
    onDelete: async (id) => {
      await del(`/api/activities/${id}`);
      setDirty(true);
      navigate(CURRENT_PATH);
    },
    onDone: () => {
      navigate(CURRENT_PATH);
    },
    onCancel: () => navigate(CURRENT_PATH)
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <ErrorsAndLoading error={error} loading={loading} />
        <div className="container safe-areas">
          <Routes>
            <Route path="new" element={<ActivityForm {...formProps} isNew />} />
            <Route path=":id" element={<ActivityForm {...formProps} />} />
            <Route
              path="*"
              element={<ActivityList activities={activities} />}
            />
          </Routes>
        </div>
      </div>
    </>
  );
};
