const de = {
  Back: "Zurück",
  "Schedule your next lesson": "Plane deine nächste Lektion",
  "Book now": "Jetzt buchen",
  Availability: "Verfügbarkeit",
  Activity: "Aktivität",
  Location: "Ort",
  What: "Was",
  Date: "Datum",
  Time: "Uhrzeit",
  "More info": "Weitere Informationen",
  Tags: "Schlagwörter",
  "Please login or signup to continue":
    "Bitte einloggen oder anmelden, um fortzufahren",
  Participant: "Teilnehmer",
  Name: "Name",
  "ID or Passport number": "Ausweis- oder Passnummer",
  ID: "Ausweis",
  "Phone number": "Telefonnummer",
  Age: "Alter",
  Phone: "Telefon",
  "Date of birth": "Geburtsdatum",
  DOB: "Geb.dat.",
  "Add participant": "Teilnehmer hinzufügen",
  "Extra notes": "Zusätzliche Notizen",
  Continue: "Weiter",
  Contacts: "Kontakte",
  "Save for next time": "Für das nächste Mal speichern",
  "Booking summary": "Buchungszusammenfassung",
  "Submitted, please check your email.":
    "Übermittelt, bitte überprüfe deine E-Mails.",
  "Agree with terms and conditions": "Mit den Bedingungen einverstanden sein",
  Link: "Link",
  "Read now": "Jetzt lesen",
  Edit: "Bearbeiten",
  Confirm: "Bestätigen",
  "Sign in or sign up": "Einloggen oder Registrieren",
  Admin: "Administration",
  "Account settings": "Kontoeinstellungen",
  "Sign out": "Ausloggen",
  "Sign up": "Anmeldung",
  Login: "Einloggen",
  login: "einloggen",
  signup: "anmelden",
  "Verify password": "Passwort bestätigen",
  Home: "Startseite",
  About: "Über uns",
  Posts: "Beiträge",
  Pricing: "Preisgestaltung",
  "Terms and Conditions": "Allgemeine Geschäftsbedingungen",
  "My bookings": "Meine Buchungen",
  "Continue without account": "Ohne Konto fortfahren",
  today: "heute",
  "Product ID": "#",
  Item: "Artikel",
  Price: "Preis",
  "Be up to date": "Sei auf dem neuesten Stand",
  "Be notified when we post new lessons":
    "Erhalte eine Benachrichtigung, wenn wir neue Lektionen veröffentlichen",
  Subscribe: "Abonnieren",
  "Want to be up to date? Click here":
    "Möchtest du auf dem Laufenden sein? Hier klicken",
  "No planned classes, please check later":
    "Keine geplanten Kurse, bitte später überprüfen",
  All: "Alle",
  "Manage Booking": "Buchung verwalten",
  "Cancel booking": "Buchung stornieren",
  "Yes, cancel booking": "Ja, Buchung stornieren",
  "No, do not cancel": "Nein, nicht stornieren",
  "Error loading booking": "Fehler beim Laden der Buchung",
  "Cookie policy text": "Wir verwenden Cookies nur, um",
  "Privacy Policy": "Datenschutzerklärung",
  "Terms & Conditions": "Nutzungsbedingungen",
  Cookies: "Cookies",
  "Subscribe to new classes email notifications":
    "Abonnieren Sie Benachrichtigungen per E-Mail für neue Kurse",
  "Follow us": "Folgen Sie uns",
  "Sold out!": "Ausverkauft!",
  "Pay now": "Jetzt bezahlen",
  "Pay later": "Später bezahlen",
  "Payment canceled, please try again":
    "Zahlung abgebrochen, bitte versuchen Sie es erneut",
  "Payment processed successfully. Thank you ❤️":
    "Zahlung erfolgreich verarbeitet. Vielen Dank ❤️",
  "Subscribe to push notifications": "Für Push-Benachrichtigungen anmelden 🔔",
  "Unsubscribe to push notifications":
    "Von Push-Benachrichtigungen abmelden 🔕",
  "Total amount": "Gesamtbetrag zu zahlen"
};

export default de;
