import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AuthConsumer} from "./auth-provider";
import Nav from "./components/nav";

import {NotFound, NotAuthorized} from "./components";
import {Home, HomeCoverImage} from "./containers/home";
import {LessonList} from "./containers/lessons/lesson-list";
import {AuthSuccess} from "./containers/auth/auth-success";
import {Login} from "./containers/auth/login";
import {Register} from "./containers/auth/register";
import {Posts, Post} from "./containers/pages/posts";
import {Pricing} from "./containers/pages/pricing";
import {About} from "./containers/pages/about";
import {TermsConditions} from "./containers/pages/terms-conditions";
import {Me} from "./containers/account/me";
import {Booking} from "./containers/booking";
import {Bookings} from "./containers/pages/bookings";
import {Loading, Footer} from "./components";
import {AuthProvider} from "./auth-provider";
import {LangProvider} from "./lang-provider";
import {Flash} from "./flash";
import {BookForm} from "./containers/book-form";
import {SubscribeConfirm} from "./containers/subscribe-confirm";
import PaymentConfirm from "./containers/payment-confirm";
import {Manage} from "./containers/manage/index";

import {fetchAuxData} from "./ducks/aux";

import {WebPushProvider} from "push-provider";

// TODO: move this to config
const getLangs = () => ["pt", "en", "es", "fr", "de"];
const getLangDescription = () => ({
  pt: "🇵🇹 PT",
  en: "🇬🇧 EN",
  es: "🇪🇸 ES",
  fr: "🇫🇷 FR",
  de: "🇩🇪 DE"
});

const getDefaultLanguage = () => {
  try {
    const language = window.navigator.language.split("-")[0];
    if (getLangs().indexOf(language) !== -1) {
      return language;
    }
  } catch (e) {}
  return "en";
};

const Layout = (props) => (
  <div className="container mx-auto px-3 max-w-md	safe-areas">
    {props.children}
  </div>
);

const App = () => {
  const isLoading = useSelector((state) => state.loading.isLoading);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchAuxData());
  }, [dispatch]);

  // can't use useParams hook here - not in context
  const params = new URLSearchParams(window.location.search);

  const isIframe = params.get("iframe") === "1";

  return (
    <WebPushProvider filename="/sw.js">
      <LangProvider
        langs={getLangs()}
        descriptions={getLangDescription()}
        defaultLang={params.get("lang") || getDefaultLanguage()}>
        <AuthProvider>
          <Router>
            <>
              {!isIframe && (
                <>
                  <Nav />
                  <Routes>
                    <Route path="/" element={<HomeCoverImage />} />
                    <Route path="*" element={<></>} />
                  </Routes>
                </>
              )}
              <Layout>
                {isLoading ? <Loading /> : null}
                <Flash />
                <Routes>
                  <Route
                    path="/manage/*"
                    element={
                      <AuthConsumer>
                        {(auth) =>
                          auth.isAdmin ? <Manage /> : <NotAuthorized />
                        }
                      </AuthConsumer>
                    }></Route>
                  <Route path="/about" element={<About />} />
                  <Route
                    path="/terms-and-conditions"
                    element={<TermsConditions />}
                  />
                  <Route path="/lessons" element={<LessonList />} />
                  <Route path="/lessons/:id" element={<BookForm />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/auth/facebook/success"
                    element={<AuthSuccess />}
                  />
                  <Route path="/posts" element={<Posts />} />
                  <Route path="/post/:id" element={<Post />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/bookings" element={<Bookings />} />
                  <Route path="/booking/:id" element={<Booking />} />
                  <Route path="/subscribe/:id" element={<SubscribeConfirm />} />
                  <Route
                    path="/payment-confirmed"
                    element={<PaymentConfirm />}
                  />
                  <Route path="/me" element={<Me />} />
                  <Route element={NotFound} />
                </Routes>
              </Layout>
              {!isIframe && <Footer />}
            </>
          </Router>
        </AuthProvider>
      </LangProvider>
    </WebPushProvider>
  );
};

export default App;
