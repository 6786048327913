import React from "react";
import {Link} from "react-router-dom";
import {PageTitle} from ".";

export const NotFound = () => (
  <div>
    <PageTitle>404 - Not Found!</PageTitle>
    <div className="text-center my-10">
      <Link className="bg-blue-500 p-5 hover:bg-blue-100" to="/">
        Go to homepage
      </Link>
    </div>
  </div>
);
