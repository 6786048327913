import produce from "immer";

// actions
const LOADING_PUSH = "LOADING_PUSH";
const LOADING_POP = "LOADING_POP";

const initialState = {
  counter: 0,
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_PUSH:
      return produce(state, (draft) => {
        draft.counter++;
        draft.isLoading = draft.counter !== 0;
      });
    case LOADING_POP:
      return produce(state, (draft) => {
        draft.counter--;
        draft.isLoading = draft.counter !== 0;
      });
    default:
      return state;
  }
};

// action creators
export const loadingPush = () => ({
  type: LOADING_PUSH
});

export const loadingPop = () => ({
  type: LOADING_POP
});

export default reducer;
