import React from "react";
import {useSelector} from "react-redux";
import {PageTitle} from "../components";
import {LessonList} from "./lessons/lesson-list";
import {TranslateString} from "../lang-provider";

import {config} from "../config";

const HomeAlert = () => {
  const text = useSelector(
    (state) => state.aux?.data?.settings?.home_alert?.value
  );
  return text ? (
    <div
      className="py-3 px-5 my-4 bg-yellow-100 text-yellow-900 text-sm rounded-md border border-yellow-200"
      role="alert">
      {text}
    </div>
  ) : null;
};

// TODO: take colors from config
export const HomeCoverImage = () => {
  const style = config.coverBackground
    ? {background: config.coverBackground}
    : {};
  return config.coverImage ? (
    <div
      className="bg-gradient-to-br from-gray-200 via-white to-gray-300"
      style={style}>
      <div className="mx-auto max-w-md z-20">
        <img src={`${process.env.PUBLIC_URL}${config.coverImage}`} alt="" />
      </div>
    </div>
  ) : null;
};

export const Home = () => {
  return (
    <React.Fragment>
      <HomeAlert />
      <PageTitle>
        <TranslateString>Schedule your next lesson</TranslateString>
      </PageTitle>
      <LessonList />
    </React.Fragment>
  );
};
