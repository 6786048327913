import {API_ENDPOINT} from "./config";

export const fetchJson = async (
  url,
  {method = "GET", body, returnHeaders = false} = {}
) => {
  console.log({url, method, returnHeaders});
  const res = await fetch(`${API_ENDPOINT}${url}`, {
    method,
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: body && JSON.stringify(body)
  });

  const json = await res.json();

  if (!res.ok) {
    throw new Error(json.message || "not a 200 response");
  }

  return returnHeaders
    ? [
        json,
        [...res.headers.entries()].reduce((o, [k, v]) => {
          o[k] = v;
          return o;
        }, {})
      ]
    : json;
};
