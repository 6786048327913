import React, {Component} from "react";

export class AuthSuccess extends Component {
  componentDidMount() {
    // const url = "/private";
    // window.opener.open(url, "_self");
    window.opener.focus();
    window.opener.location.reload();
    window.close();
  }

  render() {
    return <div>AUTH SUCCESS!</div>;
  }
}
