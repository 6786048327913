export const loadState = (key) => {
  const emptyVal = {};
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState) {
      return JSON.parse(serializedState);
    }
  } catch (e) {}
  return emptyVal;
};

export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (e) {}
};
