import React from "react";
import {useNavigate, useParams} from "react-router";

import {PageTitle} from "../../components";
import {TranslateString} from "../../lang-provider";

export function Bookings() {
  const {id} = useParams();
  return (
    <React.Fragment>
      <PageTitle>
        <TranslateString>My Bookings</TranslateString>
      </PageTitle>
      WIP
    </React.Fragment>
  );
}
