import React from "react";
import {noop} from "lodash";
import classnames from "classnames";
import dayjs from "dayjs";

import {range} from "lang";

import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isToday from "dayjs/plugin/isToday";

import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";

dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isToday);

const DATE_FORMAT = "YYYY-MM-DD";

export const ActCalendar = (props) => {
  const weekEndDays = [0, 6];

  const {year, month, dayChecker} = props;

  const day1 = dayjs(new Date(year, month, 1));

  const mockDay = {
    isToday: noop,
    date: noop,
    day: noop,
    isBefore: noop,
    isSameOrAfter: noop,
    format: () => ""
  };

  const days = [
    ...range(day1.day()).map((_v) => mockDay),
    ...range(day1.daysInMonth(), 1).map((_v, index) => day1.add(index, "days")),
    ...range(7 - day1.endOf("month").day() - 1).map((_v) => mockDay)
  ];

  const perChunk = 7;
  const rows = days.reduce((acc, curr, i) => {
    const ch = Math.floor(i / perChunk);
    acc[ch] = [].concat(acc[ch] || [], curr);
    return acc;
  }, []);

  const validDay = (day) => {
    return day.isSameOrAfter(dayjs(), "day") && dayChecker(day);
  };

  const handleClick = (day) => {
    if (validDay(day)) {
      props.onSelect(day.format(DATE_FORMAT));
    }
  };

  const handleChange = (diff) => {
    let y = day1.year();
    const m = day1.month() + diff;
    if (m < 0) {
      y--;
    } else if (m > 11) {
      y++;
    }
    props.onChange(y, m);
  };

  return (
    <div>
      <div className="py-2 flex gap-2 flex justify-between">
        <button onClick={handleChange.bind(null, -1)}>« Prev</button>
        <span className="text-center py-2 font-bold">
          {day1.format("MMMM YYYY")}
        </span>
        <button onClick={handleChange.bind(null, 1)}>Next »</button>
      </div>
      <table className="table w-full">
        <thead>
          <tr className="bg-blue-300">
            {rows[1].map((day) => (
              <th className="p-2" key={day}>
                {day.format("ddd")}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((day, colIndex) => (
                <td
                  key={colIndex}
                  onClick={handleClick.bind(null, day)}
                  className={classnames("p-2 text-center", {
                    "font-bold bg-blue-100": weekEndDays.includes(colIndex),
                    "bg-yellow-200": day.isToday(),
                    "text-gray-300 line-through": !validDay(day)
                  })}>
                  {day.date()}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
