import produce from "immer";

import {fetchJson} from "../api";
import {loadingPush, loadingPop} from "./loading";

// actions
const USER_DATA_LOAD = "USER_DATA_LOAD";
const USER_DATA_OK = "USER_DATA_OK";
const USER_DATA_ERR = "USER_DATA_ERR";
const USER_LOGOUT = "USER_LOGOUT";
const USER_SIGNIN = "USER_SIGNIN";
const USER_SIGNIN_OK = "USER_SIGNIN_OK";
const USER_SIGNIN_ERR = "USER_SIGNIN_ERR";
const USER_SIGNUP = "USER_SIGNUP";
const USER_SIGNUP_OK = "USER_SIGNUP_OK";
const USER_SIGNUP_ERR = "USER_SIGNUP_ERR";
const USER_SIGNUP_CLEAR = "USER_SIGNUP_CLEAR";

const initialState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_DATA_LOAD:
      return produce(state, (draft) => {
        draft.error = false;
        draft.errorMessage = null;
      });
    case USER_DATA_OK:
      return produce(state, (draft) => {
        draft.data = action.data;
      });
    case USER_DATA_ERR:
      return produce(state, (draft) => {
        // draft.error = true;
      });
    case USER_LOGOUT:
      return produce(state, (draft) => {
        draft.data = null;
      });
    case USER_SIGNIN:
      return produce(state, (draft) => {
        draft.error = false;
      });
    case USER_SIGNIN_OK:
      return produce(state, (draft) => {
        draft.data = action.data;
      });
    case USER_SIGNIN_ERR:
      return produce(state, (draft) => {
        draft.error = true;
        draft.errorMessage = action.message;
      });
    case USER_SIGNUP:
      return produce(state, (draft) => {});

    case USER_SIGNUP_OK:
      return produce(state, (draft) => {
        draft.registrationOk = true;
      });
    case USER_SIGNUP_ERR:
      return produce(state, (draft) => {
        draft.error = true;
        draft.errorMessage = action.message;
      });
    case USER_SIGNUP_CLEAR:
      return produce(state, (draft) => {
        draft.registrationOk = false;
      });
    default:
      return state;
  }
};

export const getUser = () => ({type: USER_DATA_LOAD});

export const getUserSuccess = (data) => ({type: USER_DATA_OK, data});

export const getUserFailure = () => ({type: USER_DATA_ERR});

export const signOut = () => ({type: USER_LOGOUT});

export const signIn = () => ({type: USER_SIGNIN});

export const signInSuccess = (data) => ({type: USER_SIGNIN_OK, data});

export const signInFailure = (message) => ({type: USER_SIGNIN_ERR, message});

export const registerUser = () => ({type: USER_SIGNUP});

export const registerSuccess = () => ({type: USER_SIGNUP_OK});

export const registerFailure = (message) => ({type: USER_SIGNUP_ERR, message});

export const clearRegistration = () => ({type: USER_SIGNUP_CLEAR});

export const fetchUserData = () => {
  return async (dispatch) => {
    dispatch(getUser());
    // dispatch(pushLoading());
    try {
      const response = await fetchJson("/api/user/me");
      dispatch(getUserSuccess(response));
    } catch (error) {
      dispatch(getUserFailure());
    }
    // dispatch(popLoading());
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    try {
      await fetchJson("/api/user/me", {method: "DELETE"});
      dispatch(signOut());
    } catch (error) {}
  };
};

export const loginUser = (email, password) => {
  return async (dispatch) => {
    dispatch(signIn());
    try {
      const response = await fetchJson("/api/login", {
        method: "POST",
        body: {email, password}
      });
      dispatch(signInSuccess(response));
    } catch (error) {
      dispatch(signInFailure());
    }
  };
};

export const signupUser = (email, password) => {
  return async (dispatch) => {
    dispatch(registerUser());
    try {
      const response = await fetchJson("/api/register", {
        method: "POST",
        body: {email, password}
      });
      dispatch(registerSuccess(response));
    } catch (error) {
      console.log("!!! signup error", error);
      dispatch(registerFailure(error));
    }
  };
};

export default reducer;
