import React, {useState, useContext, useEffect} from "react";
import {fetchJson} from "api";
import {VAPID_PUBLIC_KEY} from "config";

// TODO major cleanup
// TODO make other langs work

const WebPushContext = React.createContext(null);

const applicationServerKey = VAPID_PUBLIC_KEY;

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function checkPushPermission(cb) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.pushManager.getSubscription().then((subscription) => {
      if (subscription === undefined || subscription == null) {
        console.log("no valid subscription");
        // update_subscription_info(null);
        cb(null);
      } else {
        const json = JSON.stringify(subscription.toJSON(), null, 2);
        console.log("subscription", json);
        // update_subscription_info(json);
        console.log(json);
        cb(json);
      }
    });
  });
}

// TODO move this to a utils file
export const subscribe = async (json, lang = "en") => {
  const url = `/api/push_subscribers`;
  try {
    const res = await fetchJson(url, {
      method: "POST",
      body: {json, lang}
    });
    return res.id;
  } catch (e) {
    return false;
  }
};

export const fetchUnsubscribe = async (id, lang = "en") => {
  const url = `/api/push_subscribers/${id}`;
  try {
    await fetchJson(url, {
      method: "DELETE"
    });
    return true;
  } catch (e) {
    return false;
  }
};

export const WebPushProvider = (props) => {
  const {filename} = props;
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    if ("serviceWorker" in navigator && "Notification" in window) {
      navigator.serviceWorker
        .register(filename)
        .then(() => {
          checkPushPermission((json) => {
            if (!json) {
              console.log("not subscribed");
            }
            setSubscription(json);
          });
        })
        .catch((e) => alert(e));
    }
  }, [filename]);

  function getPushPermission() {
    navigator.serviceWorker.getRegistration().then((registration) => {
      registration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(applicationServerKey)
        })
        .then(
          (subscription) => {
            const json = JSON.stringify(subscription.toJSON(), null, 2);
            console.log(json);
            // update_subscription_info(json);

            setSubscription(json);

            subscribe(json).then((id) => {
              window.sessionStorage.setItem("webPushId", id);
            });
          },
          (error) => {
            console.log("error getting push permission", error);
            setSubscription(null);
          }
        );
    });
  }

  function unsubscribePush() {
    navigator.serviceWorker.ready.then((registration) => {
      registration.pushManager.getSubscription().then((subscription) => {
        if (subscription === undefined || subscription == null) {
          console.log("unsubscribe: no subscription found");
        } else {
          subscription
            .unsubscribe()
            .then((successful) => {
              if (successful) {
                setSubscription(null);
                fetchUnsubscribe(window.sessionStorage.getItem("webPushId"));
              }
            })
            .catch((e) => {
              console.log("unsubscribe error", e);
            });
        }
      });
    });
  }

  return (
    <WebPushContext.Provider
      value={{subscribed: !!subscription, getPushPermission, unsubscribePush}}>
      {props.children}
    </WebPushContext.Provider>
  );
};

export const useWebPushProvider = () => {
  return useContext(WebPushContext);
};
