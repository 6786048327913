import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";

import {Alert, ConfirmButton, PageTitle, Label} from "../components";
import {useLangProvider, TranslateString} from "../lang-provider";
import {fetchLessonsData} from "../ducks/lessons";
import {fetchAuxData} from "../ducks/aux";
import {fetchBookingData, cancelBooking} from "../ducks/booking";

const useLessons = (id) => {
  const dispatch = useDispatch();
  const {booking, error, lesson, auxData} = useSelector((state) => ({
    booking: state.booking.booking,
    error: state.booking.error,
    lesson: state.lessons.data,
    auxData: state.aux.data
  }));

  useEffect(() => {
    if (!booking) {
      dispatch(fetchBookingData(id));
      dispatch(fetchAuxData());
    } else if (!lesson) {
      dispatch(fetchLessonsData());
    }
  }, [booking, lesson, id, dispatch]);

  return {
    booking,
    error,
    lesson,
    auxData
    // processedLesson: item && auxData ? processItem(item, auxData) : {}
  };
};

export const Booking = (props) => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const {T} = useLangProvider();

  const {booking, error} = useLessons(id);

  if (error) {
    return (
      <Alert type="error">
        <TranslateString>Error loading booking</TranslateString>
      </Alert>
    );
  }

  if (!booking) {
    return "no booking found with this id";
  }

  const {phone, email, notes} = booking;
  const data = JSON.parse(booking.data);

  return (
    <React.Fragment>
      <PageTitle>
        <TranslateString>Manage Booking</TranslateString>
      </PageTitle>
      <div className="bg-gray-50 hover:bg-gray-100 my-2 p-3 flex flex-col justify-between gap-5">
        {phone && (
          <div>
            <Label>
              <TranslateString>Phone</TranslateString>
            </Label>
            {phone}
          </div>
        )}
        {email && (
          <div>
            <Label>
              <TranslateString>Email</TranslateString>:
            </Label>
            {email}
          </div>
        )}
        {notes && (
          <div>
            <Label>
              <TranslateString>Notes</TranslateString>:
            </Label>
            {notes}
          </div>
        )}

        {data && (
          <>
            <div>
              <Label>
                <TranslateString>Users</TranslateString> ({data.length}):
              </Label>
            </div>

            <ul>
              {data.map((row, index) => (
                <li className="list-decimal list-inside" key={index}>
                  {row.name} ({row.age || "-"})
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className="mt-5 flex flex-col items-center">
        <ConfirmButton
          color="destructive"
          title={T("Cancel booking")}
          confirmTitle={T("Yes, cancel booking")}
          cancelTitle={T("No, do not cancel")}
          confirmAction={() => dispatch(cancelBooking(id))}>
          <TranslateString>Cancel booking</TranslateString>
        </ConfirmButton>
      </div>
    </React.Fragment>
  );
};
