import React from "react";
import {Routes, Route, Link} from "react-router-dom";
import {useLocation} from "react-router-dom";

import {Tags} from "./tags";
import {Posts} from "./posts";
import {Activities} from "./activities";
import {Settings} from "./settings";
import {Instructors} from "./instructors";
import {Lessons} from "./lessons";
import {Products} from "./products";
import {Dashboard} from "./dashboard";

import "share-api-polyfill";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const BubbleMenu = ({items}) => {
  const loc = useLocation();
  const {pathname} = loc;
  const p = pathname.replace(/\/manage\/?/, "");
  const selectedIndex = items.reduce(
    (prev, curr, index) => (p.startsWith(curr.url) ? index : prev),
    0
  );
  return (
    <div className="flex flex-wrap gap-1 mb-5">
      {items.map(({url, title}, index) => (
        <div key={url}>
          <Link
            to={url}
            className={classNames(
              selectedIndex === index
                ? "bg-gray-700 text-gray-100"
                : "bg-gray-100 text-gray-700",
              "block px-4 py-2 text-sm"
            )}>
            {title}
          </Link>
        </div>
      ))}
    </div>
  );
};

export const Manage = () => {
  const items = [
    {url: ``, title: "Management Dashboard"},
    {url: `lessons`, title: "Lessons"},
    {url: `activities`, title: "Activities"},
    {url: `tags`, title: "Tags"},
    {url: `posts`, title: "Posts"},
    {url: `settings`, title: "Settings"},
    {url: `instructors`, title: "Instructors"},
    {url: `products`, title: "Products"}
  ];
  return (
    <div>
      <BubbleMenu items={items} />
      <Routes>
        <Route path="activities/*" element={<Activities />} />
        <Route path="lessons/*" element={<Lessons />} />
        <Route path="posts/*" element={<Posts />} />
        <Route path="tags/*" element={<Tags />} />
        <Route path="settings/*" element={<Settings />} />
        <Route path="instructors/*" element={<Instructors />} />
        <Route path="products/*" element={<Products />} />
        {/* <Route path="*" element={<Dashboard />} /> */}
        <Route index element={<Dashboard />} />
      </Routes>
    </div>
  );
};
