import React from "react";
import {Link, useLocation} from "react-router-dom";
import {MdChevronRight} from "react-icons/md";

export const List = ({children}) => {
  return <div className="">{children}</div>;
};

export const ListItem = ({to, children, naked = false}) => {
  return naked ? (
    <Link to={to}>{children}</Link>
  ) : (
    <div className="border-t p-3">
      <Link className="flex flex-row justify-between items-center" to={to}>
        <div className="flex flex-row gap-2 items-center">{children}</div>
        <MdChevronRight />
      </Link>
    </div>
  );
};

export const ListFooter = ({children}) => (
  <div className="flex flex-row justify-between items-center back pb-2 gap-1">
    {children}
  </div>
);

export const FormLabel = ({children}) => (
  <div className="font-bold my-3">{children}</div>
);

export const CTALink = ({to, children}) => (
  <Link
    className="bg-blue-500 border-blue-500 border-2 text-white text-center font-bold p-2"
    to={to}>
    {children}
  </Link>
);

export const Button = ({children, ...props}) => (
  <button
    className="border border-blue-500 border-2 text-blue-500 text-center font-bold p-2"
    {...props}>
    {children}
  </button>
);

export const Summary = ({children}) => (
  <div className="text-sm">{children}</div>
);

export const LoadMoreButton = ({children, ...props}) => (
  <button className="w-full bg-gray-200 text-center font-bold p-2" {...props}>
    {children}
  </button>
);

// export const Title = ({children}) => (
//   <h1 className="text-2xl py-2 my-2">{children}</h1>
// );

// export const HorizontalMenu = (props) => {
//   return (
//     <div className="relative my-2 z-0">
//       <div className="overflow-auto flex flex-row">
//         <div
//           className="bg-gradient-to-r from-white to-transparent
//             w-5 z-2 absolute left-0 top-0 bottom-0"
//         />
//         <div
//           className="bg-gradient-to-r from-transparent to-white
//             w-5 z-2 absolute right-0 top-0 bottom-0"
//         />
//         {props.children}
//       </div>
//     </div>
//   );
// };

// export const MenuItem = ({to, children, ...props}) => {
//   const {pathname} = useLocation();
//   return (
//     <div
//       className={`rounded bg-blue-100 mr-1 p-2 ${
//         to === pathname ? "bg-blue-400 text-white" : ""
//       }`}>
//       <Link className="" to={to}>
//         {children}
//       </Link>
//     </div>
//   );
// };

export const ErrorsAndLoading = (props) => {
  return (
    <>
      {props.error && (
        <div class="font-regular block w-full bg-pink-500 p-4 text-base leading-5 text-white opacity-100">
          Error
        </div>
      )}
      {props.loading && <span class="admin-loader"></span>}
    </>
  );
};

export const Label = ({children, ...props}) => (
  <label {...props} className="uppercase text-xs font-bold flex gap-2">
    {children}
  </label>
);
