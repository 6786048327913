import {TranslateString} from "../../lang-provider";
import {SubscribeForm} from "../subscribe-form";

export const NoData = ({selectedActivity}) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-3/4 m-20 flex flex-col gap-5">
        <div className="bg-blue-100 p-4 text-center rounded-sm">
          <TranslateString>
            No planned classes, please check later
          </TranslateString>
        </div>
        <SubscribeForm selectedActivity={selectedActivity} />
      </div>
    </div>
  );
};
