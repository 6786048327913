import produce from "immer";

import {fetchJson} from "../api";
import {loadingPush, loadingPop} from "./loading";

// actions
const POSTS_DATA_LOAD = "POSTS_DATA_LOAD";
const POSTS_DATA_OK = "POSTS_DATA_OK";
const POSTS_DATA_ERR = "POSTS_DATA_ERR";

const initialState = {
  data: [],
  err: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POSTS_DATA_LOAD:
      return produce(state, (draft) => {
        draft.err = false;
      });
    case POSTS_DATA_OK:
      return produce(state, (draft) => {
        draft.data = action.data;
      });
    case POSTS_DATA_ERR:
      return produce(state, (draft) => {
        draft.err = true;
      });
    default:
      return state;
  }
};

// Action Creators
export const loadPostsData = () => ({
  type: POSTS_DATA_LOAD
});

export const postsDataUpdate = (data) => ({type: POSTS_DATA_OK, data});

export const postsDataFailed = () => ({type: POSTS_DATA_ERR});

export const fetchPostsData = () => {
  return async (dispatch) => {
    dispatch(loadingPush());
    dispatch(loadPostsData());
    try {
      const response = await fetchJson(
        `/api/posts?sort=["creation_date","desc"]`
      );
      dispatch(postsDataUpdate(response));
    } catch (e) {
      dispatch(postsDataFailed());
    }
    dispatch(loadingPop());
  };
};

export default reducer;
