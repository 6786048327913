import React from "react";
import {complementaryTextColor} from "../common/utils";

export const Tag = (props) => {
  const tag = props.tag;
  if (!tag) {
    return "deleted";
  }
  return (
    <span
      key={tag.name}
      className="rounded-lg mr-1 px-2 py-1 inline-block text-xs"
      style={{
        backgroundColor: tag.color,
        color: complementaryTextColor(tag.color)
      }}>
      {tag.name}
    </span>
  );
};
