import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchAuxData} from "../../ducks/aux";
import {fetchLessonsData} from "../../ducks/lessons";

import {Item} from "./lesson-list-item";
import {NoData} from "./components";
import {HomeFilter} from "./home-filter";
import {ActCalendar} from "../act-calendar";

const ENABLE_RECURRING = false;

export const LessonList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [newBooking, setNewBooking] = useState({});
  const [selectedActivity, setSelectedActivity] = useState();
  const [times, setTimes] = useState();

  const {loading, error, data, activities} = useSelector((state) => ({
    loading: state.aux.loading || state.lessons.loading,
    error: state.aux.error,
    data: state.lessons.data,
    activities: state.aux.data.activities
  }));

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());

  useEffect(() => {
    dispatch(fetchLessonsData());
    dispatch(fetchAuxData());
    // dispatch(setInitialBookinData());
  }, [dispatch]);

  if (loading) {
    return "";
  }

  if (error) {
    return "oops, error";
  }

  const handleClick = (id) => {
    navigate(`/lessons/${id}?activity=${selectedActivity || ""}`);
  };

  const showTimes = (day) => {
    // create mock booking
    setNewBooking({activity: activity.id, date: day, time: null});
    if (activity && activity.recurrent) {
      const {times} = JSON.parse(activity.recurrent_config);
      if (times.length > 1) {
        setTimes(times);
      } else {
        // auto select and proceeed
        const time = times[0];
        navigate(
          `/lessons/${newBooking.date}?activity=${newBooking.activity}&time=${time}`
        );
      }
    }
    return true;
  };

  const handleSelectTime = (time) => {
    // setNewBooking((prev) => ({ ...prev, time }));
    navigate(
      `/lessons/${newBooking.date}?activity=${newBooking.activity}&time=${time}`
    );
  };

  const handleCancel = () => {
    setTimes(null);
  };

  const filteredData = data.filter(
    (item) =>
      !selectedActivity ||
      (selectedActivity && item.activity_id === selectedActivity)
  );

  const activity =
    selectedActivity && activities.filter(($) => $.id === selectedActivity)[0];

  const dayCheck = (day) => {
    if (activity && activity.recurrent) {
      const {days} = JSON.parse(activity.recurrent_config);
      const dow = day.format("ddd").toLowerCase();
      return days.includes(dow);
    }
    return true;
  };

  return (
    <>
      <HomeFilter
        selectedActivity={selectedActivity}
        handleSelectActivity={setSelectedActivity}
      />

      {filteredData.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {/* <PreItem item={item} prev={index > 0 ? data[index - 1] : null} /> */}
            <Item key={index} {...{item, handleClick}} />
          </React.Fragment>
        );
      })}

      {filteredData.length === 0 ? (
        <NoData selectedActivity={selectedActivity} />
      ) : null}

      {ENABLE_RECURRING && selectedActivity && (
        <div className="relative">
          <ActCalendar
            year={year}
            month={month}
            onChange={(y, m) => {
              setYear(y);
              setMonth(m);
            }}
            onSelect={showTimes}
            dayChecker={dayCheck}
          />
          {times && (
            <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center flex-col gap-2 backdrop-blur-sm bg-black/50 p-2 text-white">
              <p className="font-bold">{newBooking.date}</p>
              <p>Time: </p>
              {times.map((timeSlot) => (
                <button
                  key={timeSlot.name}
                  className="btn"
                  onClick={() => handleSelectTime(timeSlot.name)}>
                  {timeSlot.name}
                </button>
              ))}
              <button className="btn-secondary" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
