export const Alert = ({type = "info", children} = {}) => {
  let cn = null;
  switch (type) {
    case "error":
      cn = "bg-red-500 border-red-700";
      break;
    case "info":
    default:
      cn = "bg-green-500 border-green-700";
      break;
  }
  return (
    <div
      className={`flex items-center border-l-4 py-2 px-3 shadow-md my-2 ${cn}`}>
      <div className="text-white max-w-xs">{children}</div>
    </div>
  );
};
