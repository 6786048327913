const pt = {
  Back: "Voltar",
  "Schedule your next lesson": "Reserva a tua próxima aula",
  "Book now": "Reserva já",
  Availability: "Disponibilidade",
  Activity: "Atividade",
  Location: "Local",
  What: "Atividade",
  Date: "Data",
  Time: "Hora",
  "More info": "Outras informações",
  Tags: "Etiquetas",
  "Please login or signup to continue":
    "Inicie sessão ou registe-se para continuar",
  // booking form
  Participant: "Participante",
  Name: "Nome",
  "ID or Passport number": "Número de CC ou passaporte",
  ID: "CC/Pass.",
  "Phone number": "Número de telefone",
  Age: "Idade",
  Phone: "Telefone",
  "Date of birth": "Data de nascimento",
  DOB: "DN",
  "Add participant": "Acrescentar participante",
  "Extra notes": "Notas extra",
  Continue: "Continuar",
  Contacts: "Contactos",
  "Save for next time": "Guardar para próxima vez",
  "Booking summary": "Resumo da reserva",
  "Submitted, please check your email.":
    "Submetido! Por favor confirme a recepção da reserva no seu email",
  "Agree with terms and conditions": "Concordo com os termos e condições",
  Link: "Ligação",
  "Read now": "Consultar",
  Edit: "Alterar",
  Confirm: "Confirmar",
  "Sign in or sign up": "Iniciar sessão",
  Admin: "Administração",
  "Account settings": "Definições de conta",
  "Sign out": "Encerrar sessão",
  "Sign up": "Criar conta",
  Login: "Iniciar sessão",
  login: "login",
  signup: "registo",
  "Verify password": "Repetir password",
  //
  Home: "Início",
  About: "Sobre",
  Posts: "Novidades",
  Pricing: "Preços",
  "Terms and Conditions": "Termos e condições",
  //
  "My bookings": "Minhas reservas",
  "Continue without account": "Continuar sem criar conta",
  //
  today: "hoje",
  "in X days": ({num}) => {
    switch (num) {
      case 0:
        return "hoje";
      case 1:
        return "amanhã";
      case 2:
        return "depois de amanhã";
      default:
        return `daqui a ${num} dias`;
    }
  },
  "X days after": ({num}) => {
    switch (num) {
      case 0:
        return null;
      case 1:
        return "um dia depois";
      default:
        return `${num} dias depois`;
    }
  },
  //
  "Product ID": "#",
  Item: "Item",
  Price: "Preço",
  // subscribe form
  "Be up to date": "Mantém-te informado",
  "Be notified when we post new lessons":
    "Recebe uma notificação quando actualizamos esta página",
  Subscribe: "Subscrever",
  "Want to be up to date? Click here": "Queres manter-te informado? Clica aqui",
  //
  "No planned classes, please check later":
    "Sem aulas planeadas, por favor volta mais tarde",
  //
  All: "Todos",
  // edit booking
  "Manage Booking": "Gerir reserva",
  "Cancel booking": "Cancelar reserva",
  "Yes, cancel booking": "Sim, cancelar a reserva",
  "No, do not cancel": "Não, não cancelar",
  "Error loading booking": "Erro ao carregar a reserva",

  "Cookie policy text": "Utilizamos cookies apenas para ",

  "Privacy Policy": "Política de privacidade",
  "Terms & Conditions": "Termos & Condições",
  Cookies: "Cookies",
  "Subscribe to new classes email notifications":
    "Receber notificações de novas aulas (email)",
  "Follow us": "Contactos",

  "Sold out!": "Esgotado",
  "Pay now": "Pagar agora",
  "Pay later": "Pagar depois",
  "Book now and pay later": "Reservar agora e pagar depois",

  "Subscribe to push notifications": "Ativar notificações push 🔔",
  "Unsubscribe to push notifications": "Cancelar notificações push 🔕",

  "Payment canceled, please try again":
    "Pagamento cancelado, por favor tente novamente",
  "Payment processed successfully. Thank you ❤️":
    "Pagamento com sucesso. Obrigado ❤️",

  "Total amount": "Total a pagar"
};

export default pt;
