import React from "react";
import {config} from "../config";
import {TranslateString} from "../lang-provider";

import {SocialMedia} from "../components/social-media";

import {useWebPushProvider} from "push-provider";

const Sep = () => <span className="inline-block mx-1">•</span>;

export const Footer = (props) => {
  const {subscribed, getPushPermission, unsubscribePush} = useWebPushProvider();
  return (
    <div className="container mx-auto text-center text-sm flex flex-col gap-5 my-10 text-gray-400">
      <SocialMedia />

      <div>
        {!subscribed ? (
          <button onClick={getPushPermission}>
            <TranslateString>Subscribe to push notifications</TranslateString>
          </button>
        ) : (
          <button onClick={unsubscribePush}>
            <TranslateString>Unsubscribe to push notifications</TranslateString>
          </button>
        )}
      </div>
      {/* <p>
        {config.name} is powered by{" "}
        <a href="https://nowtical.com">nowtical.com</a>.
      </p> */}
      <div className="align-center text-xs">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://nowtical.com/privacy-policy">
          <TranslateString>Privacy Policy</TranslateString>
        </a>
        <Sep />
        <a
          target="_blank"
          rel="noreferrer"
          href="https://nowtical.com/terms-and-conditions">
          <TranslateString>Terms &amp; Conditions</TranslateString>
        </a>
        <Sep />
        <a target="_blank" rel="noreferrer" href="https://nowtical.com/cookies">
          <TranslateString>Cookies</TranslateString>
        </a>
      </div>
    </div>
  );
};
