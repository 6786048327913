import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {PageTitle} from "../../components";
import {useLangProvider, TranslateString} from "../../lang-provider";

export const Me = (props) => {
  const {T} = useLangProvider();

  const user = useSelector((state) => state.user.data);

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);

  useEffect(() => {
    setName(user?.name);
    setEmail(user?.email);
    setPhone(user?.phone);
  }, [user]);

  return (
    <div>
      <PageTitle>
        <TranslateString>Account</TranslateString>
      </PageTitle>

      <div>
        <TranslateString>Name</TranslateString>
        <input
          className="w-full"
          type="text"
          placeholder={T("Name")}
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TranslateString>Email</TranslateString>
        <input
          className="w-full"
          type="text"
          placeholder={T("Email")}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <TranslateString>Phone number</TranslateString>
        <input
          className="w-full border border-gray-400 pl-5"
          type="text"
          placeholder={T("Phone number")}
          country="PT"
          international={false}
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
        />
      </div>
    </div>
  );
};
