const getConfig = () => {
  const {hostname} = window.document.location;
  const common = {
    shareableUrl: `https://${hostname}`,
    excludedTimes: [
      [0, 0],
      [0, 30],
      [1, 0],
      [1, 30],
      [2, 0],
      [2, 30],
      [3, 0],
      [3, 30],
      [4, 0],
      [4, 30],
      [5, 0],
      [5, 30],
      [6, 0],
      [6, 30],
      [20, 0],
      [20, 30],
      [21, 0],
      [21, 30],
      [22, 0],
      [22, 30],
      [23, 0],
      [23, 30]
    ]
  };
  switch (hostname) {
    case "migas.nowtical.com":
      return {
        ...common,
        name: "Migas Surf School",
        logo: "/images/migas/logo.png",
        coverImage: "/images/migas/cover-image.jpg",
        api: "https://api.nowtical.com/tenant/migas"
      };
    case "secret.nowtical.com":
      return {
        ...common,
        name: "Secret Surf",
        logo: "/images/secret/logo.png",
        api: "https://api.nowtical.com/tenant/secret"
      };
    case "testing123.nowtical.com":
      return {
        ...common,
        name: "Testing 123 Surf School",
        logo: "/images/testing123/logo.jpeg",
        coverImage: "/images/testing123/cover-image.jpg",
        api: "https://api.nowtical.com/tenant/testing123"
      };
    case "localhost":
    case "nowtical1.local":
      return {
        ...common,
        shareableUrl: "http://localhost:3500",
        name: "The Localhost Surf School",
        logo: "/images/localhost/logo.png",
        coverImage: "/images/migas/cover-image.jpg",
        coverBackground: `linear-gradient(45deg, rgba(0, 220, 200, 0.3), #fff, rgba(200, 0, 0, 0.2))`,
        // api: "http://localhost:8080"
        api: "http://localhost:8080/tenant/migas"
      };
    default:
      // FIXME
      window.location = "/notfound.html";
  }
};

// TODO: make getconfig accept a param with hostname
export const config = getConfig();
export const API_ENDPOINT = config.api;
export const VAPID_PUBLIC_KEY =
  "BLeMhSSbro1G-RYCFfC6eXeswTeMJhfZ0VXgucIqQBgkrH0qoPQvsxMLqH2wva7NvFFkEMx0TjYmBGorWzUmedU";

// when testing auth and stuff (localtunnel)
// export const API_ENDPOINT = "https://plastic-turtle-19.loca.lt";
