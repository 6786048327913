import React, {useState, useContext} from "react";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";

import "dayjs/locale/pt";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/de";

import * as langs from "./langs";

dayjs.extend(localeData);
dayjs.extend(localizedFormat);

// READ THIS FOR INSPIRATION
// https://marmelab.com/react-admin/Translation.html

const LangContext = React.createContext(null);

const translate = (lang, key, data) => {
  const dict = langs[lang];
  const translated = dict ? dict[key] : null;
  if (translated) {
    if (typeof translated === "function") {
      return translated(data);
    } else {
      return translated;
    }
  } else if (lang !== "en") {
    console.log("!!! Not translated: ", key, lang);
  }
  return key;
};

export const LangProvider = (props) => {
  const {langs, descriptions} = props;
  const [lang, setLang] = useState(props.defaultLang);

  dayjs.locale(lang);

  return (
    <LangContext.Provider value={{lang, setLang, langs, descriptions}}>
      {props.children}
    </LangContext.Provider>
  );
};

export const TranslateString = (props) => {
  const {lang} = useContext(LangContext);
  return translate(lang, props.children, props.data);
};

export const LangConsumer = (props) => {
  const {lang, setLang, langs, descriptions} = useContext(LangContext);
  return props.children(lang, setLang, langs, descriptions);
};

// hook
export const useLangProvider = () => {
  const {lang} = useContext(LangContext);
  return {
    lang,
    T: (key) => {
      return translate(lang, key);
    }
  };
};
