import produce from "immer";

import {fetchJson} from "../api";
import {loadingPush, loadingPop} from "./loading";

// actions
const LESSONS_DATA_LOAD = "LESSONS_DATA_LOAD";
const LESSONS_DATA_OK = "LESSONS_DATA_OK";
const LESSONS_DATA_ERR = "LESSONS_DATA_ERR";

const initialState = {
  data: [],
  err: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LESSONS_DATA_LOAD:
      return produce(state, (draft) => {
        draft.err = false;
      });
    case LESSONS_DATA_OK:
      return produce(state, (draft) => {
        draft.data = action.data;
      });
    case LESSONS_DATA_ERR:
      return produce(state, (draft) => {
        draft.err = true;
      });
    default:
      return state;
  }
};

// Action Creators
export const loadLessonsData = () => ({
  type: LESSONS_DATA_LOAD
});

export const lessonsDataUpdate = (data) => ({type: LESSONS_DATA_OK, data});

export const lessonsDataFailed = () => ({type: LESSONS_DATA_ERR});

export const fetchLessonsData = () => {
  return async (dispatch) => {
    dispatch(loadLessonsData());
    dispatch(loadingPush());
    try {
      const response = await fetchJson(`/api/lessons?next=1`);
      dispatch(lessonsDataUpdate(response));
    } catch (error) {
      dispatch(lessonsDataFailed());
    }
    dispatch(loadingPop());
  };
};

export default reducer;
