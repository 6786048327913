import {createStore, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";

import {combineReducers} from "redux";

import auxReducer from "./ducks/aux";
import loadingReducer from "./ducks/loading";
import postsReducer from "./ducks/posts";
import userReducer from "./ducks/user";
import lessonsReducer from "./ducks/lessons";
import bookingReducer from "./ducks/booking";
import productsReducer from "./ducks/products";

const rootReducer = combineReducers({
  aux: auxReducer,
  lessons: lessonsReducer,
  user: userReducer,
  booking: bookingReducer,
  loading: loadingReducer,
  posts: postsReducer,
  products: productsReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
