import produce from "immer";

import {fetchJson} from "../api";
import {loadingPush, loadingPop} from "./loading";

// actions
const PRODUCTS_DATA_LOAD = "PRODUCTS_DATA_LOAD";
const PRODUCTS_DATA_OK = "PRODUCTS_DATA_OK";
const PRODUCTS_DATA_ERR = "PRODUCTS_DATA_ERR";

const initialState = {
  data: [],
  err: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_DATA_LOAD:
      return produce(state, (draft) => {
        draft.err = false;
      });
    case PRODUCTS_DATA_OK:
      return produce(state, (draft) => {
        draft.data = action.data;
      });
    case PRODUCTS_DATA_ERR:
      return produce(state, (draft) => {
        draft.err = true;
      });
    default:
      return state;
  }
};

// Action Creators
export const loadProductsData = () => ({
  type: PRODUCTS_DATA_LOAD
});

export const productsDataUpdate = (data) => ({type: PRODUCTS_DATA_OK, data});

export const productsDataFailed = () => ({type: PRODUCTS_DATA_ERR});

export const fetchProductsData = () => {
  return async (dispatch) => {
    dispatch(loadingPush());
    dispatch(loadProductsData());
    try {
      const response = await fetchJson(
        `/api/products?sort=["order_num","asc"]`
      );
      dispatch(productsDataUpdate(response));
    } catch (e) {
      dispatch(productsDataFailed());
    }
    dispatch(loadingPop());
  };
};

export default reducer;
