const en = {
  test: "Test",
  "in X days": ({num}) => {
    switch (num) {
      case 0:
        return "today";
      case 1:
        return "tomorrow";
      case 2:
        return "after tomorrow";
      default:
        return `in ${num} days`;
    }
  },
  "X days after": ({num}) => {
    switch (num) {
      case 0:
        return null;
      case 1:
        return "one day later";
      default:
        return `${num} days later`;
    }
  },
  "Subscribe to push notifications": "Subscribe to push notifications 🔔",
  "Unsubscribe to push notifications": "Unsubscribe to push notifications 🔕"
};

export default en;
