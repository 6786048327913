import React, {useEffect, useState} from "react";
// import useFetch from "use-http";
import {useFetch} from "containers/use-fetch";
import _, {clamp, head} from "lodash";
import {Routes, Route, useParams, Navigate} from "react-router-dom";
import {Formik, Field, Form} from "formik";
import {useNavigate} from "react-router-dom";
import {config, API_ENDPOINT} from "../../config";
import {
  List,
  ListItem,
  CTALink,
  ListFooter,
  FormLabel,
  LoadMoreButton,
  Summary,
  ErrorsAndLoading,
  Button
} from "./components";
import dayjs from "dayjs";
import {useField, useFormikContext} from "formik";
import DatePicker from "react-datepicker";
import {Item} from "../lessons/lesson-list-item";
import {fetchJson} from "../../api";
import {formatDate} from "common/utils";

import "react-datepicker/dist/react-datepicker.css";

const PAGE_SIZE = 10;

const addManualBooking = async (lesson, phone, name, num) => {
  const users = _.range(num).map((index) => ({
    name: `${name} - ${index + 1}`,
    age: ""
  }));

  try {
    const url = `/api/lessons/${lesson.id}/bookings`;
    await fetchJson(url, {
      method: "POST",
      body: {
        users,
        phone,
        email: null,
        notes: "manual add",
        user_id: "0",
        lesson
      }
    });
    return true;
  } catch (error) {
    return false;
  }
};

// TODO make this modal
const ExpandButton = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && props.children}
      <Button onClick={() => setOpen(!open)}>
        {open ? "Cancel" : props.title}
      </Button>
    </>
  );
};

export const DatePickerField = ({...props}) => {
  const {setFieldValue} = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

const LessonForm = ({
  onCreate,
  onUpdate,
  onDelete,
  initialValues,
  data,
  isNew,
  auxData
}) => {
  const [isDone, setIsDone] = useState(false);
  const {id} = useParams();
  const values = isNew ? initialValues : data.find((el) => el.id === id);

  const navigate = useNavigate();

  if (!data && !auxData) return "";

  if (isDone) {
    navigate("../");
    return <div />;
  }

  return (
    <div className="">
      <ListFooter>
        <Button onClick={() => setIsDone(true)}>← Back</Button>
      </ListFooter>
      <Formik
        initialValues={values}
        // validate=...
        onSubmit={(values) =>
          (isNew ? onCreate : onUpdate)(values) && setIsDone(true)
        }>
        <Form className="flex flex-col gap-2">
          <FormLabel>Activity</FormLabel>
          {Object.keys(auxData.activities).map((id) => (
            <label>
              <Field type="radio" name="activity_id" value={id} />
              {auxData.activities[id].name} - {auxData.activities[id].place}
            </label>
          ))}

          <FormLabel>Date</FormLabel>
          <DatePickerField
            inline
            type="text"
            id="date"
            name="date"
            placeholder="date"
            autoComplete="off"
            showTimeSelect
            // filterTime=(time => {
            // })
            excludeTimes={config.excludedTimes.map(([h, m]) =>
              dayjs().hour(h).minute(m).toDate()
            )}
            timeFormat="HH:mm"
            className="w-full"
            // TODO: filter dates and times
          />

          <FormLabel>Num places</FormLabel>
          <Field
            type="text"
            id="num_places"
            name="num_places"
            placeholder="num places"
            autoComplete="off"
          />

          <FormLabel>Instructors</FormLabel>
          {Object.keys(auxData.instructors).map((id) => (
            <label>
              <Field type="checkbox" name="instructors" value={id} />
              {auxData.instructors[id].name}
            </label>
          ))}

          <FormLabel>Tags</FormLabel>
          {Object.keys(auxData.tags).map((id) => (
            <label>
              <Field type="checkbox" name="tags" value={id} />
              {auxData.tags[id].name}
            </label>
          ))}

          <Button type="submit">{isNew ? "Create" : "Update"}</Button>
          <Button onClick={() => setIsDone(true)}>Cancel</Button>
          {!isNew && (
            <Button
              color="destructive"
              onClick={() => onDelete(id) && setIsDone(true)}>
              Delete
            </Button>
          )}
        </Form>
      </Formik>
    </div>
  );
};

const ManualAddForm = (props) => {
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [num, setNum] = useState(1);
  return (
    <div className="flex flex-col gap-2">
      <input
        type="text"
        placeholder="phone"
        className="flex-grow"
        onChange={(e) => setPhone(e.target.value)}
      />
      <div className="flex flex-row gap-2">
        <input
          type="text"
          placeholder="name"
          className="flex-grow"
          onChange={(e) => setName(e.target.value)}
        />
        <select onChange={(e) => setNum(e.target.value)}>
          <option>1</option>
          <option>2</option>
          <option>3</option>
        </select>
      </div>
      <Button
        onClick={async () => {
          const ok = await addManualBooking(props.lesson, phone, name, num);
          if (ok) {
            window.location.reload();
          } else {
            alert("failure");
          }
        }}>
        Add
      </Button>
    </div>
  );
};

const LessonShow = ({onCreate, onUpdate, data, url, isNew, path, auxData}) => {
  const [isDone, setIsDone] = useState(false);
  const {id} = useParams();
  const [values, setValues] = useState(null);
  const [bookings, setBookings] = useState([]);

  const {get} = useFetch(API_ENDPOINT);
  const navigate = useNavigate();

  async function loadOne(id) {
    const data = await get(`/api/lessons/${id}`);
    setValues(data);
  }

  useEffect(() => {
    loadOne(id);
  }, []);

  useEffect(() => {
    if (isDone) {
      navigate("../");
    }
  }, [isDone]);

  if (!values || !auxData) {
    return "...";
  }

  const {bookingData} = values;

  const renderRow = (booking, index) => {
    const userData = JSON.parse(booking.data);
    const span = userData.length;
    const hasNotes = !!booking.notes;
    return (
      <React.Fragment key={booking.id}>
        {userData.map(({name, age}, userDataIndex) => (
          <tr key={`{booking.id}-${userDataIndex}`} className="border-solid">
            {userDataIndex === 0 && (
              <>
                <th className="border p-1" rowSpan={span + (hasNotes ? 2 : 1)}>
                  {index + 1}
                </th>
                <td className="border p-1" rowSpan={span}>
                  <a href={`mailto:${booking.email}`}>{booking.email || "-"}</a>
                </td>
                <td className="border p-1" rowSpan={span}>
                  <a href={`tel:${booking.phone}`}>{booking.phone || "-"}</a>
                </td>
              </>
            )}
            <td className="border p-1">{`${name} (${age || "-"})`}</td>
            {userDataIndex === 0 && (
              <td className="border p-1 text-center" rowSpan={span}>
                {booking.stripe_session_id ? "✅" : "❌"}
              </td>
            )}
          </tr>
        ))}
        {hasNotes && (
          <tr>
            <td className="p-1" colSpan={4}>
              {booking.notes}
            </td>
          </tr>
        )}
        <tr>
          <td className="border p-1" colSpan={4}>
            {formatDate(booking.creation_date)}
          </td>
        </tr>
      </React.Fragment>
    );
  };

  return (
    <div className="flex flex-col gap-5">
      <ListFooter>
        <Button onClick={() => setIsDone(true)}>← Back</Button>
        <CTALink to="edit">Edit</CTALink>
      </ListFooter>
      <Item item={values} showExtraInfo={true} omitButton={true} />

      <p className="uppercase font-bold">Bookings</p>

      <table className="table-auto border border-collapse border-gray-200">
        <thead>
          <tr>
            <th className="border bg-gray-100 p-1"></th>
            <th className="border bg-gray-100 p-1">Email</th>
            <th className="border bg-gray-100 p-1">Phone</th>
            <th className="border bg-gray-100 p-1">Participants</th>
            <th className="border bg-gray-100 p-1">Paid</th>
          </tr>
        </thead>
        <tbody>{bookingData.map(renderRow)}</tbody>
      </table>

      <div className="flex flex-col gap-2">
        <ExpandButton title="Manual add">
          <ManualAddForm lesson={values} />
        </ExpandButton>
      </div>
    </div>
  );
};

export const fetchAuxData = async (get) => {
  const [activities, instructors, tags, stats] = await Promise.all([
    get("/api/activities"),
    get("/api/instructors"),
    get("/api/tags"),
    get("/api/stats")
  ]);
  return {
    activities: _.keyBy(activities, "id"),
    instructors: _.keyBy(instructors, "id"),
    tags: _.keyBy(tags, "id"),
    stats
  };
};

export const fetchLessonData = async (get, startOffset = 0) => {
  const [lessons, headers] = await get(
    `/api/lessons?sort=["date","DESC"]&range=[${startOffset},${PAGE_SIZE}]`,
    {returnHeaders: true}
  );

  const range = headers["content-range"];
  console.log(headers, range);
  const [, first, count, total] = range.match(/([0-9]+)-([0-9]+)\/([0-9]+)/);
  console.log({first, count, total});
  return [lessons, first, count, total];
};

export const Lessons = (props) => {
  const [aux, setAux] = useState({activities: []});
  const [lessons, setLessons] = useState([]);
  const [total, setTotal] = useState(0);
  const [first, setFirst] = useState(0);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const {get, post, put, del, response, loading, error} =
    useFetch(API_ENDPOINT);

  const loadInitialData = async () => {
    const aux = await fetchAuxData(get);
    const [lessons, first, count, total] = await fetchLessonData(get);
    setAux(aux);
    setLessons(lessons);
    setTotal(Number(total));
    setFirst(Number(first));
    setCount(Number(count));
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const formProps = {
    data: lessons,
    auxData: aux,
    total,
    first,
    count,
    initialValues: {
      name: ""
    },
    loadMore: async () => {
      const [newLessons, newFirst, newCount, newTotal] = await fetchLessonData(
        first + count
      );
      setLessons([...lessons, ...newLessons]);
      setTotal(Number(newTotal));
      setFirst(Number(newFirst));
      setCount(Number(newCount));
    },
    onCancel: () => navigate(-1),
    onCreate: async (values) => {
      const newLesson = await post("/api/lessons", values);
      if (response.ok) {
        setLessons([...lessons, newLesson]);
      }
    },
    onUpdate: async (values) => {
      const {id} = values;
      const updatedLesson = await put(`/api/lessons/${id}`, values);
      if (response.ok) {
        const newLessons = [...lessons];
        const index = lessons.findIndex((el) => el.id === id);
        newLessons[index] = updatedLesson;
        setLessons(newLessons);
      }
    },
    onDelete: async (id) => {
      const response = await del(`/api/lessons/${id}`);
      if (response.ok) {
        const newLessons = [...lessons];
        const index = lessons.findIndex((el) => el.id === id);
        delete newLessons[index];
        setLessons(newLessons);
      }
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <ErrorsAndLoading error={error} loading={loading} />
      <div className="container safe-areas">
        <Routes>
          <Route path="new" element={<LessonForm {...formProps} isNew />} />
          <Route path=":id/edit" element={<LessonForm {...formProps} />} />
          <Route path=":id" element={<LessonShow {...formProps} />} />
          <Route path="*" element={<LessonList {...formProps} />} />
        </Routes>
      </div>
    </div>
  );
};

const LessonList = (props) => {
  const {data, total, first, count, loadMore} = props;

  const hasMore = first + count < total;

  return (
    <List>
      <ListFooter>
        <Summary>
          {total} items (showing {clamp(first + count, 0, total)})
        </Summary>
        <CTALink to="new">+ Add new</CTALink>
      </ListFooter>
      {data.map((item, index) => (
        <ListItem key={item.id} to={item.id} naked={true}>
          <Item item={item} showExtraInfo={true} omitButton={true} />
        </ListItem>
      ))}
      {hasMore && <LoadMoreButton onClick={loadMore}>Load more</LoadMoreButton>}
    </List>
  );
};
