import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {fetchUserData, logoutUser} from "./ducks/user";

const AuthContext = React.createContext(null);

export const AuthProvider = (props) => {
  const dispatch = useDispatch();
  const {user} = useSelector((state) => ({
    loading: state.loading.isLoading,
    user: state.user.data
  }));

  React.useEffect(() => {
    dispatch(fetchUserData());
  }, []);

  const isAdmin = user?.is_admin || false;

  return (
    <AuthContext.Provider
      value={{user, isAdmin, logout: () => dispatch(logoutUser())}}>
      {/* {props.loading ? <Loading /> : null} */}
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = (props) => {
  const {user, logout, isAdmin} = React.useContext(AuthContext);
  return props.children({isSignedIn: !!user, user, logout, isAdmin});
};

// TODO: add something like this:

// {
// /* <IfFirebaseAuthed>
// {() => {
// return <div>You are authenticated</div>;
// }}
// </IfFirebaseAuthed> */
// }
// {
// /* <IfFirebaseAuthedAnd
// filter={({providerId}) => providerId !== "anonymous"}>
// {({providerId}) => {
// return <div>You are authenticated with {providerId}</div>;
// }}
// </IfFirebaseAuthedAnd> */
// }
